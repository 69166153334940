import { useQuery } from '@tanstack/react-query';
import { QueryConfig } from '../../../lib/react-query';
import { CoordinateRef } from '../../../models/ApiTypes';
import { api } from '../../../lib/api-client';

export const getCRS = async (search): Promise<CoordinateRef[]> => {
    return api.get(`/Inbound/coordinate-ref-system?search=${search}`)
}

export const getCRSQueryOptions = (search: string) => {
    return {
        queryKey: ['crs', { search }],
        queryFn: () => getCRS(search),
    };
};

type UseCRSOptions = {
    search: string
    queryConfig?: QueryConfig<typeof getCRSQueryOptions>;
};

export const useCRS = ({ search, queryConfig }: UseCRSOptions) => {
    return useQuery({
        ...getCRSQueryOptions(search),
        ...queryConfig,
    });
};
