import './SpudLetterManagementToolbar.scss';

import { useDispatch } from 'react-redux';
import _ from 'underscore';

import { DocumentManagementToolbar, IDocumentManagementToolbarLayout } from '@Eni/docware-fe-master';

import { getLettersQueryOptions } from '@/features/home/api/get-letters';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getSignerOfRole } from '../../features/form/components/review/review';
import { FileUtils } from '../../models/FileUtils';
import { WorkflowRole } from '../../models/SpudLetterDto';
import { SpudLetterStatus } from '../../models/SpudLetterStatus';
import { UserRole } from '../../models/UserRole';
import ApiService from '../../services/api-service';
import { APISettings, IAPIResponse } from '../../services/internal/ajax-service';
import { useLoaderContext } from '../../stores/loader-context';
import { ToolbarActions } from '../../stores/toolbar/toolbar-action';
import { IBaseFile, NavbarState } from '../../stores/toolbar/toolbar-state';
import { useUserContext } from '../../stores/user-context';
import { DOCUMENT_URLS } from '../../utils/api-urls';
import AppRoutes, { DocwareRoutes } from '../../utils/AppRoutes';
import { FormMode } from '../../utils/FormMode';
import WindowToast from '../../utils/window-toast';

export interface SpudLetterManagementToolbarProps {
    type: NavbarState;
    currentFile?: IBaseFile;
    selectedFiles?: IBaseFile[];
    reloadPage?: () => void;
}

export const SpudLetterManagementToolbar = (props: SpudLetterManagementToolbarProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loader = useLoaderContext();
    const queryClient = useQueryClient();

    const { user: currentUser } = useUserContext();
    const isUserAdmin = currentUser?.roles?.some((x) => x === UserRole.Admin);
    const isUserApprover = currentUser?.roles?.some((x) => x === UserRole.Approver);
    const isUserReader = currentUser?.roles?.some((x) => x === UserRole.Reader);
    const isUserWriter = currentUser?.roles?.some((x) => x === UserRole.Writer);

    const checkInvalidFields = (wellCode: string, callback: () => void) => {
        loader.show();
        ApiService.SpudLetterController.getInvalidFieldsForApproval(wellCode)
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw response.error;

                let errorMessages = response.payload as string[];
                if (errorMessages.length === 0) {
                    callback();
                } else {
                    WindowToast.warning('The following fields are not valid for approval: ' + errorMessages.join(', '));
                }
            })
            .catch(WindowToast.error)
            .finally(loader.hide);
    };

    const gotToReviewRequest = (wellCode: string) => {
        checkInvalidFields(wellCode, () =>
            navigate(`${AppRoutes.DETAIL.replace(':wellCode', wellCode)}?action=reviewRequest`),
        );
    };

    const gotToApprovalRequest = (wellCode: string) => {
        checkInvalidFields(wellCode, () =>
            navigate(`${AppRoutes.DETAIL.replace(':wellCode', wellCode)}?action=approvalRequest`),
        );
    };

    const goToDeAuthorizationRequest = (wellCode: string) => {
        navigate(`${AppRoutes.DETAIL.replace(':wellCode', wellCode)}?action=de-authorize`);
    };

    const getHomeNavbar = () => {
        const menuLayouts: IDocumentManagementToolbarLayout = {
            name: 'navigation-voices',
            content: {
                leftButtons: [
                    {
                        text: 'Admin Area',
                        iconName: 'Admin',
                        inactive: !isUserAdmin,
                        onClick: () => navigate(AppRoutes.ADMIN),
                    },
                ],
                rightButtons: [],
            },
        };

        return menuLayouts;
    };

    const getDetailNavbar = () => {
        const { currentFile } = props;

        let EM = getSignerOfRole(currentFile.signers, WorkflowRole.ExplorationManager);
        let EMDeputy = getSignerOfRole(currentFile.signers, WorkflowRole.ExplorationManagerDeputy);

        let isExpManager = !!EM && EM.id === currentUser.id;
        let isExpManagerDeputy = !!EMDeputy && EMDeputy.id === currentUser.id;

        const menuLayouts: IDocumentManagementToolbarLayout = {
            name: 'navigation-voices',
            content: {
                leftButtons: [
                    {
                        text: 'Home',
                        iconName: 'Home',
                        onClick: () => navigate(AppRoutes.HOME),
                    },
                    {
                        text: 'Edit',
                        iconName: 'Edit',
                        inactive:
                            currentFile.locked ||
                            (currentFile.status !== SpudLetterStatus.Draft &&
                                currentFile.status !== SpudLetterStatus.ReviewedDraft) ||
                            !isUserWriter,
                        onClick: () => {
                            let mode = FormMode.View;
                            if (currentFile.status === SpudLetterStatus.Draft) {
                                mode = FormMode.Edit;
                            }
                            if (currentFile.status === SpudLetterStatus.ReviewedDraft) {
                                mode = FormMode.ReviewedEdit;
                            }
                            navigate(AppRoutes.FORM.replace(':wellCode', currentFile.wellCode), {
                                state: { mode },
                            });
                        },
                    },
                    {
                        text: 'Share',
                        iconName: 'Share',
                        inactive: currentFile.status !== SpudLetterStatus.Signed || !isUserAdmin,
                        onClick: () => {
                            dispatch(ToolbarActions.setShowDistributionModal(true));
                        },
                    },
                    currentFile.ownership === 'E' && currentFile.status === SpudLetterStatus.Draft
                        ? {
                              text: 'Send for Review',
                              iconName: 'CheckList',
                              inactive:
                                  currentFile.locked ||
                                  currentFile.status !== SpudLetterStatus.Draft ||
                                  (!isExpManager && !isExpManagerDeputy),
                              onClick: () => gotToReviewRequest(currentFile.wellCode),
                          }
                        : {
                              text: 'Workflow',
                              iconName: 'CheckList',
                              inactive:
                                  currentFile.locked ||
                                  (currentFile.status !== SpudLetterStatus.Draft &&
                                      currentFile.status !== SpudLetterStatus.ReviewedDraft) ||
                                  !isUserWriter,
                              onClick: () => gotToApprovalRequest(currentFile.wellCode),
                          },
                    {
                        text: 'De-authorize',
                        iconName: 'Blocked',
                        inactive:
                            currentFile.status !== SpudLetterStatus.Signed &&
                            currentFile.status !== SpudLetterStatus.ReviewedDraft,
                        onClick: () => goToDeAuthorizationRequest(currentFile.wellCode),
                        disabled: !isUserAdmin,
                    },
                    {
                        text: 'Download',
                        iconName: 'Download',
                        onClick: () => {
                            FileUtils.downloadFromUrl(
                                `${APISettings.baseUrl}${DOCUMENT_URLS.MAINFILE_PREVIEW.replace(
                                    '{wellCode}',
                                    currentFile.wellCode,
                                )}`,
                                currentFile.documentName,
                            );
                        },
                    },
                    {
                        text: 'View in Docware Drive',
                        iconName: 'FabricFolder',
                        inactive: !currentFile.docwareDocumentId,
                        onClick: () => {
                            window.location.href = `/${DocwareRoutes.DRIVE_DOCUMENT__MANAGMENT}?documentid=${currentFile.docwareDocumentId}`;
                            // navigate(
                            //     `/${DocwareRoutes.DRIVE_DOCUMENT__MANAGMENT}?documentid=${currentFile.docwareDocumentId}`,
                            // );
                        },
                    },
                ],
                rightButtons: [],
            },
        };

        return menuLayouts;
    };

    const getSelectedFilesNavbar = () => {
        const { selectedFiles } = props;

        let menuLayout: IDocumentManagementToolbarLayout = null;

        const deleteFiles = async () => {
            loader.show();
            await Promise.all(
                selectedFiles.map((x) =>
                    ApiService.SpudLetterController.deleteSpudLetter(x.wellCode)
                        .then((response: IAPIResponse) => {
                            if (response.error !== null) throw response.error;
                            WindowToast.success(`Spud Letter ${x.wellCode} deleted successfully`);
                            queryClient.invalidateQueries(getLettersQueryOptions('').queryKey);
                        })
                        .catch(WindowToast.error)
                        .finally(loader.hide),
                ),
            );
            loader.hide();
            if (props.reloadPage) props.reloadPage();
        };

        if (selectedFiles.length == 1) {
            let file: IBaseFile = _.first(selectedFiles);

            let EM = _.first(file.signers.filter((x) => x.workflowRole === WorkflowRole.ExplorationManager));
            let EMDeputy = _.first(
                file.signers.filter((x) => x.workflowRole === WorkflowRole.ExplorationManagerDeputy),
            );

            let isExpManager = !!EM && EM.id === currentUser.id;
            let isExpManagerDeputy = !!EMDeputy && EMDeputy.id === currentUser.id;

            menuLayout = {
                name: 'navigation-voices',
                content: {
                    leftButtons: [
                        {
                            text: 'View',
                            iconName: 'Page',
                            inactive: !isUserReader,
                            onClick: () => {
                                navigate(AppRoutes.FORM.replace(':wellCode', file.wellCode), {
                                    state: { mode: FormMode.View },
                                });
                            },
                        },
                        {
                            text: 'Edit',
                            iconName: 'Edit',
                            inactive:
                                file.locked ||
                                (file.status !== SpudLetterStatus.Draft &&
                                    file.status !== SpudLetterStatus.ReviewedDraft) ||
                                !isUserWriter,
                            onClick: () => {
                                let mode = FormMode.View;
                                if (file.status === SpudLetterStatus.Draft) {
                                    mode = FormMode.Edit;
                                }
                                if (file.status === SpudLetterStatus.ReviewedDraft) {
                                    mode = FormMode.ReviewedEdit;
                                }
                                navigate(AppRoutes.FORM.replace(':wellCode', file.wellCode), {
                                    state: { mode },
                                });
                            },
                        },
                        file.ownership === 'E' && file.status === SpudLetterStatus.Draft
                            ? {
                                  text: 'Send for Review',
                                  iconName: 'CheckList',
                                  inactive:
                                      file.locked ||
                                      file.status !== SpudLetterStatus.Draft ||
                                      (!isExpManager && !isExpManagerDeputy),
                                  onClick: () => gotToReviewRequest(file.wellCode),
                              }
                            : {
                                  text: 'Workflow',
                                  iconName: 'CheckList',
                                  inactive:
                                      file.locked ||
                                      (file.status !== SpudLetterStatus.Draft &&
                                          file.status !== SpudLetterStatus.ReviewedDraft) ||
                                      !isUserWriter,
                                  onClick: () => gotToApprovalRequest(file.wellCode),
                              },
                        {
                            text: 'PDF Preview',
                            iconName: 'PDF',
                            onClick: () => navigate(AppRoutes.DETAIL.replace(':wellCode', file.wellCode)),
                        },
                        {
                            text: 'Move to Trash',
                            iconName: 'RecycleBin',
                            inactive: !isUserWriter || file.status !== SpudLetterStatus.Draft,
                            onClick: deleteFiles,
                        },
                    ],
                    rightButtons: [],
                },
            };
        } else {
            menuLayout = {
                name: 'navigation-voices',
                content: {
                    leftButtons: [
                        {
                            text: 'Move to Trash',
                            iconName: 'RecycleBin',
                            inactive: !isUserWriter || selectedFiles.some((x) => x.status !== SpudLetterStatus.Draft),
                            onClick: deleteFiles,
                        },
                    ],
                    rightButtons: [],
                },
            };
        }

        return menuLayout;
    };

    const getSpudLetterFormNavbar = () => {
        const { currentFile } = props;

        const menuLayouts: IDocumentManagementToolbarLayout = {
            name: 'navigation-voices',
            content: {
                leftButtons: [
                    {
                        text: 'Home',
                        iconName: 'Home',
                        onClick: () => navigate(AppRoutes.HOME),
                    },
                    {
                        text: 'PDF Preview',
                        iconName: 'PDF',
                        onClick: () => navigate(AppRoutes.DETAIL.replace(':wellCode', currentFile.wellCode)),
                    },
                ],
                rightButtons: [],
            },
        };

        return menuLayouts;
    };

    const getAdminNavbar = () => {
        const menuLayouts: IDocumentManagementToolbarLayout = {
            name: 'navigation-voices',
            content: {
                leftButtons: [
                    {
                        text: 'Home',
                        iconName: 'Home',
                        onClick: () => navigate(AppRoutes.HOME),
                    },
                ],
                rightButtons: [],
            },
        };

        return menuLayouts;
    };

    const getDevNavbar = () => {
        const menuLayouts: IDocumentManagementToolbarLayout = {
            name: 'navigation-voices',
            content: {
                leftButtons: [
                    {
                        text: 'Home',
                        iconName: 'Home',
                        onClick: () => navigate(AppRoutes.HOME),
                    },
                ],
                rightButtons: [],
            },
        };

        return menuLayouts;
    };

    let layout = null;

    switch (props.type) {
        case NavbarState.Home:
            layout = getHomeNavbar();
            break;
        case NavbarState.SelectedFiles:
            layout = getSelectedFilesNavbar();
            break;
        case NavbarState.SpudLetterDetail:
            layout = getDetailNavbar();
            break;
        case NavbarState.SpudLetterForm:
            layout = getSpudLetterFormNavbar();
            break;
        case NavbarState.Admin:
            layout = getAdminNavbar();
            break;
        case NavbarState.Dev:
            layout = getDevNavbar();
            break;
        default:
            break;
    }

    return <DocumentManagementToolbar loading={false} layout={layout} />;
};
