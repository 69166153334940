import { Link, useParams } from "react-router-dom";
import ActivityColumn, { IActivity } from "../../../components/activity-column/activity-column";
import { useUserContext } from "../../../stores/user-context";
import { useDocumentActivities } from "../api/get-document-activities";

export const ActivityList = () => {
    const params = useParams();
    const wellCode = params.wellCode as string;
    const activitiesQuery = useDocumentActivities({ wellCode });
    const { user: loggedUser } = useUserContext();

    return (
        <div style={{ padding: "1em" }}>
            <div style={{ margin: "2em 0 1em 0" }}>History</div>
            <div className="summary-container">
                <ActivityColumn
                    onRenderLink={(reference: IActivity) => {
                        let message = reference.message;
                        let creator = reference.userId === loggedUser.id ? "You" : reference.userFullName;
                        return (
                            <Link to={""} className="activity-highlight-name">
                                {creator}
                            </Link>
                        );
                    }}
                    title={"History"}
                    activityList={activitiesQuery.data}
                    showUserName
                    hideAction
                />
            </div>
        </div>
    );
};
