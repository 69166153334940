import { Link } from "react-router-dom";
import ActivityColumn, { IActivity } from "../../../components/activity-column/activity-column";
import AppRoutes from "../../../utils/AppRoutes";
import { FormMode } from "../../../utils/FormMode";
import { useUserActivities } from "../api/get-activities";

export const HomeActivityColumn = () => {
    const userActivitiesQuery = useUserActivities();

    return (
        <ActivityColumn
            onRenderLink={(reference: IActivity) => {
                let message = reference.message;
                return (
                    <Link
                        to={AppRoutes.FORM.replace(":wellCode", reference.referenceId)}
                        state={{ mode: FormMode.View }}
                    >
                        {message.length < 80 ? message : message.substring(0, 80) + "…"}
                    </Link>
                );
            }}
            title={"Your Activities"}
            activityList={userActivitiesQuery.data}
            hideAction
        />
    );
};
