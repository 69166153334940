import { match } from 'ts-pattern';
import { PendingChange } from '../pending-changes-manager/pending-changes-manager';
import { TooltipHost } from '@fluentui/react';

export const PendingChangesDialogColumns = [
  {
    name: 'Field',
    dataType: 'string',
    fieldName: 'fieldName',
    iconName: null,
    size: 'medium',
    onRender: (item: PendingChange) => {
      let fieldName = match(item.fieldName)
        .with('onshoreOffshore', () => 'Onshore/Offshore')
        .with('eniOperated', () => 'Eni Operated')
        .with('eniWiPerc', () => 'Eni Working Interest %')
        .with('eniPiPerc', () => 'Eni Participating Interest %')
        .with('XwareTight', () => 'Tight')
        .otherwise((x) => x.charAt(0).toUpperCase() + x.substring(1).toLowerCase());
      return fieldName;
    },
  },
  {
    name: 'Previous Value',
    dataType: 'string',
    fieldName: 'previousValue',
    iconName: null,
    size: 'large',
    onRender: (item: PendingChange) => {
      let value = item.previousValue?.toString();
      return <TooltipHost content={value}>{value}</TooltipHost>;
    },
  },
  {
    name: 'New Value',
    dataType: 'string',
    fieldName: 'currentValue',
    iconName: null,
    size: 'large',
    onRender: (item: PendingChange) => {
      let value = item.currentValue?.toString();
      return <TooltipHost content={value}>{value}</TooltipHost>;
    },
  },
];
