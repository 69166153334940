import { WORKFLOW_URLS } from "../../utils/api-urls";
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from "../internal/ajax-service";

const WorkflowController = {
    GetLatestWorkflow: async (wellCode: string, callback?: (response: IAPIResponse) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = WORKFLOW_URLS.GET_LATEST.replace("{wellCode}", wellCode);
        request.method = "GET";
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
};
``
export default WorkflowController;
