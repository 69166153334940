import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { useMapCenter } from '../hooks/use-map-center';
import { useFormContext } from '../stores/form-context';

const ChangeView = ({ center, zoom, disabled = false }) => {
    if (!disabled) {
        const map = useMap();
        map.setView(center, zoom);
    }
    return null;
};

export const MapWidget = () => {
    const { formState: formState } = useFormContext();

    const center = useMapCenter(
        formState.latitude,
        formState.longitude,
        formState.latDirection,
        formState.lonDirection,
    );

    let zoom = 6;

    const isMapEnabled = formState.coordinateReferenceSystem?.name
        ? formState.coordinateReferenceSystem.name.toUpperCase().includes('WGS 84')
        : false;

    return (
        <div className={`field-50 leaflet-container ${!isMapEnabled && 'disabled-leaflet-container'}`}>
            <MapContainer center={[0, 0]} zoom={13} scrollWheelZoom={isMapEnabled} dragging={isMapEnabled}>
                <ChangeView center={center} zoom={zoom} disabled={!isMapEnabled} />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {isMapEnabled && (
                    <Marker position={center}>
                        <Popup>The current chosen location</Popup>
                    </Marker>
                )}
            </MapContainer>
        </div>
    );
};
