export enum UserRole {
  Reader = 0,
  Writer = 1,
  Approver = 2,
  Admin = 3,
  Dev = 100
}

export const userRoleNameMap = [
  { key: UserRole.Reader, name: 'Reader' },
  { key: UserRole.Writer, name: 'Writer' },
  { key: UserRole.Approver, name: 'Approver' },
  { key: UserRole.Admin, name: 'Admin' },
];
