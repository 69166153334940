import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/api-client';
import { QueryConfig } from '../../../lib/react-query';
import EditLock from '../../../models/EditLock';

export const getActiveLocks = async (): Promise<EditLock[]> => {
    return api.get(`/SpudLetterEditLock/active`)
}

export const getActiveLocksQueryOptions = () => {
    return {
        queryKey: ['active-locks',],
        queryFn: () => getActiveLocks(),
    };
};

type UseActiveLocksOptions = {
    queryConfig?: QueryConfig<typeof getActiveLocksQueryOptions>;
};

export const useActiveLocks = ({ queryConfig }: UseActiveLocksOptions = {}) => {
    return useQuery({
        ...getActiveLocksQueryOptions(),
        ...queryConfig,
    });
};
