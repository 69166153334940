import "./home.scss";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { match } from "ts-pattern";

import { FileList, ModalDialog } from "@Eni/docware-fe-master";
import { FontIcon, Icon, SearchBox, Spinner } from "@fluentui/react";

import { QueryClient } from "@tanstack/react-query";
import LabelButton from "../../components/label-button/label-button";
import { SpudLetterManagementToolbar } from "../../components/spudletter-management-toolbar/SpudLetterManagementToolbar";
import { useLoaderContext } from "../../stores/loader-context";
import { useUserContext } from "../../stores/user-context";
import { useInputOptions } from "../../features/app/api/get-input-options";
import { getActiveLocksQueryOptions, useActiveLocks } from "../../features/home/api/get-active-locks";
import { getUserActivitiesQueryOptions } from "../../features/home/api/get-activities";
import { HomeActivityColumn } from "../../features/home/components/home-activity-column";
import { SpudLetterListColumns } from "../../features/home/components/table-columns";
import { useDocumentHead } from "../../hooks/use-document-head";
import { SpudLetterItem } from "../../models/SpudLetterItem";
import { GlobalState } from "../../stores/root-reducer";
import { ToolbarActions } from "../../stores/toolbar/toolbar-action";
import { IBaseFile, NavbarState } from "../../stores/toolbar/toolbar-state";
import ApiService from "../../services/api-service";
import { IAPIResponse } from "../../services/internal/ajax-service";
import AppRoutes from "../../utils/AppRoutes";
import { NUMBER_OF_SPUD_LETTERS_IN_HOMEPAGE } from "../../utils/Constants";
import { FormMode } from "../../utils/FormMode";
import { CommonModalButtons } from "../../utils/ModalUtils";
import WindowToast from "../../utils/window-toast";

dayjs.extend(utc);

export const homeLoader = (queryClient: QueryClient) => async () => {
    // const lettersQueryOptions = getLettersQueryOptions("");
    const userActivitiesQueryOptions = getUserActivitiesQueryOptions();
    const activeLocksQueryOptions = getActiveLocksQueryOptions();

    const promises = [
        // queryClient.getQueryData(lettersQueryOptions.queryKey) ?? (await queryClient.fetchQuery(lettersQueryOptions)),
        queryClient.getQueryData(userActivitiesQueryOptions.queryKey) ??
            (await queryClient.fetchQuery(userActivitiesQueryOptions)),
        queryClient.getQueryData(activeLocksQueryOptions.queryKey) ??
            (await queryClient.fetchQuery(activeLocksQueryOptions)),
    ] as const;

    const [userActivitiesQuery, activeLocksQuery] = await Promise.all(promises);

    return {
        // lettersQuery,
        userActivitiesQuery,
        activeLocksQuery,
    };
};

export const HomeRoute = () => {
    const activeLocksQuery = useActiveLocks();
    const inputOptionsQuery = useInputOptions();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchText, setSearchText] = useState<string>("");
    const [letterList, setLetterList] = useState<SpudLetterItem[]>([]);
    const [processingCreation, setProcessingCreation] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<IBaseFile[]>([]);

    // https://stackoverflow.com/questions/62340697/react-query-how-to-usequery-when-button-is-clicked
    // const letterListQuery = useLetters({ searchText });
    // const { data, refetch } = letterListQuery;
    // set enabled: false
    // manually call refetch()

    const [isActivityTabOpen, setActivityTabOpen] = useState<boolean>(window.innerWidth > 1400);

    const [access, setAccess] = useState<"" | "allowed" | "inaccessible" | "invalid">("");

    const loader = useLoaderContext();

    const showLockedModal = useSelector<GlobalState, boolean>((state) => state.toolbar.showLockedModal);
    const { user: currentUser } = useUserContext();

    useDocumentHead({ title: null });

    const getLetters = async () => {
        loader.show();
        setSelectedFiles([]);
        setAccess("");
        setLetterList([]);
        if (searchText !== "") {
            var result = await ApiService.SpudLetterController.canAccessWellCode(searchText);
            if (result.raw.status === 403) {
                setAccess("inaccessible");
                loader.hide();
                throw result.error;
            }
            if (result.raw.status === 404) {
                setAccess("invalid");
                // loader.hide();
                // throw result.error;
            }
            if (result.raw.status === 204) {
                setAccess("allowed");
            }
        }
        ApiService.SpudLetterController.getSpudLetterList({
            search: searchText,
            limit: NUMBER_OF_SPUD_LETTERS_IN_HOMEPAGE,
            offset: 0,
        })
            .then(async (response: IAPIResponse) => {
                if (response.error !== null) {
                    if (response.raw.status === 403) throw response.error;
                    else throw "There was an error accessing the file";
                }
                let payload = response.payload as SpudLetterItem[];
                setLetterList(
                    payload.map((spudLetter) => ({
                        ...spudLetter,
                        spudDate: !spudLetter.spudDate
                            ? null
                            : new Date(
                                  new Date(spudLetter.spudDate).getTime() +
                                      new Date(spudLetter.spudDate).getTimezoneOffset() * 60000
                              ),
                    }))
                );
            })
            .catch(WindowToast.error)
            .finally(loader.hide);
    };

    const createSpudLetter = () => {
        if (searchText === "") {
            WindowToast.error("Well code cannot be empty");
            return;
        }

        setProcessingCreation(true);
        ApiService.SpudLetterController.createSpudLetter(searchText)
            .then((response: IAPIResponse) => {
                if (response.error !== null) {
                    let text = match(response.raw.status)
                        .with(400, () => response.error)
                        .with(401, () => `Authentication error`)
                        .with(403, () => response.error)
                        .with(404, () => `${searchText} is not a valid or existing Well code`)
                        .with(500, () => `The server encountered an error while creating the Spud Letter`)
                        .run();
                    throw text;
                }
                navigate(AppRoutes.FORM.replace(":wellCode", response.payload.wellCode), {
                    state: { mode: FormMode.Edit },
                });
            })
            .catch((err) => WindowToast.error(err))
            .finally(() => {
                loader.hide();
                setProcessingCreation(false);
            });
    };

    useEffect(() => {
        const fetches = async () => {
            loader.show();
            const spudLetterRes = await ApiService.SpudLetterController.getSpudLetterList({
                search: "",
                limit: NUMBER_OF_SPUD_LETTERS_IN_HOMEPAGE,
                offset: 0,
            });

            if (!spudLetterRes.error) {
                const spudLetters = (spudLetterRes.payload as SpudLetterItem[]).map((x) => ({
                    ...x,
                    spudDate: x.spudDate === null ? null : new Date(x.spudDate), //TODO remove offset
                }));
                setLetterList(spudLetters);
            }
            loader.hide();
        };
        fetches();
    }, []);

    const onCloseLockedModal = () => {
        dispatch(ToolbarActions.setShowLockedModal(false));
    };

    const onCreateSpudLettter = () => {
        createSpudLetter();
    };

    let MainMessage = () => {
        if (processingCreation) {
            return (
                <div className="no-spud-letter-found-container">
                    <div className="spinner-wrap">
                        <div className="spinner-inner">
                            <Spinner label="Loading..." />
                        </div>
                    </div>
                    <div className="no-spud-letter-found-title">The Spud Letter is being created</div>
                    <div className="no-spud-letter-found-sub-title">The operation could take a few minutes</div>
                </div>
            );
        }

        // if (searchText === "" || access === "invalid") {

        // }
        if (access === "inaccessible") {
            return (
                <div className="no-spud-letter-found-container">
                    <div className="no-spud-letter-found-title">Invalid permissions</div>
                    <div className="no-spud-letter-found-sub-title">
                        The spudletter you are searching for is not accessible with your current permissions
                    </div>
                </div>
            );
        }
        if (access === "allowed") {
            return (
                <div className="no-spud-letter-found-container">
                    <FontIcon aria-label="Desert" iconName="desert-svg" />
                    <div className="no-spud-letter-found-title">No Spud Letter Found</div>
                    <div className="no-spud-letter-found-sub-title">
                        There are no Spud Letter associated to the well code you entered, do you want to create a new
                        spud letter with that code?
                    </div>
                    <LabelButton text="Create new Spud Letter" orangeSolid icon="Add" onClick={onCreateSpudLettter} />
                </div>
            );
        }

        if (access === "invalid") {
            return (
                <div className="no-spud-letter-found-container">
                    <FontIcon aria-label="Desert" iconName="desert-svg" />
                    <div className="no-spud-letter-found-title">The Well Code is invalid</div>
                    <div className="no-spud-letter-found-sub-title">
                        No well associated to the code you entered was found in Xware
                    </div>
                </div>
            );
        }
        return (
            <div className="no-spud-letter-found-container">
                <FontIcon aria-label="Desert" iconName="desert-svg" />
                <div className="no-spud-letter-found-title">No Spud Letter Found For you</div>
                <div className="no-spud-letter-found-sub-title">Please check you permissions</div>
            </div>
        );
    };

    return (
        <div className="page-wrap spud-letter-homepage-wrap">
            <section className="spud-letter-homepage-left-section">
                <div style={{ position: "fixed", width: "100%", zIndex: 1 }}>
                    <SpudLetterManagementToolbar
                        type={selectedFiles.length === 0 ? NavbarState.Home : NavbarState.SelectedFiles}
                        selectedFiles={selectedFiles.map((x) => ({
                            ...x,
                            locked: activeLocksQuery.data.some((l) => l.wellCode === x.wellCode),
                        }))}
                        reloadPage={() => navigate(".", { replace: true })}
                    />
                </div>
                <div className="spud-letter-homepage-inner-left-section">
                    <div className="page-title">Spud Letter</div>

                    <div className="search-box">
                        <SearchBox
                            placeholder="Enter well code"
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e?.target?.value ?? "");
                                setAccess("");
                            }}
                            onSearch={getLetters}
                        />
                        <LabelButton text="Search" orangeSolid onClick={getLetters} />
                    </div>

                    {letterList.length > 0 ? (
                        <div>
                            <div className="page-sub-title">RECENTLY CREATED</div>

                            <FileList
                                defaultSortOnThisColumnIndex={2}
                                columns={SpudLetterListColumns(inputOptionsQuery.data.ownershipOptions)}
                                fileIconFromField={"name"}
                                items={letterList}
                                onItemsSelected={(items: SpudLetterItem[]) => setSelectedFiles(items)}
                                currentSelectedItems={[]}
                                currentSelectedItem={null}
                                hideBottomButton={true}
                            />
                        </div>
                    ) : (
                        <MainMessage />
                    )}
                </div>
            </section>

            <section className={`spud-letter-homepage-right-section ${isActivityTabOpen ? "open" : "closed"}`}>
                <Icon iconName="DoubleChevronRight" onClick={() => setActivityTabOpen((prev) => !prev)} />
                {isActivityTabOpen && <HomeActivityColumn />}
            </section>

            <ModalDialog
                modalTitle={"Edit is temporarily unavailable"}
                modalMessage={
                    "Another user is editing this document. \n Document will be accessible in view only until this document will be saved."
                }
                enableModal={showLockedModal}
                modalButtons={CommonModalButtons.okOnlyButtons(onCloseLockedModal)}
                onAbort={onCloseLockedModal}
            />
        </div>
    );
};
