import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/api-client';
import { QueryConfig } from '../../../lib/react-query';
import { SpudLetterDto } from '../../../models/SpudLetterDto';

export const getLetter = async ({ wellCode }): Promise<SpudLetterDto> => {
    return api.get(`/spudletter/${wellCode}`)
}

export const getLetterQueryOptions = (wellCode: string) => {
    return {
        queryKey: ['letter', { wellCode }],
        queryFn: () => getLetter({ wellCode }),
    };
};

type UseLetterOptions = {
    wellCode: string
    queryConfig?: QueryConfig<typeof getLetterQueryOptions>;
};

export const useLetter = ({ wellCode, queryConfig }: UseLetterOptions) => {
    return useQuery({
        ...getLetterQueryOptions(wellCode),
        ...queryConfig,
        staleTime: 0,
        refetchInterval: false
    });
};
