import './form-layout.scss';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ModalDialog } from '@Eni/docware-fe-master';

import { SpudLetterStatus } from '@/models/SpudLetterStatus';
import { ActivityList } from '../../features/form/components/activity-list';
import EditingTimeoutManager from '../../features/form/components/editing-timeout-manager';
import FormMenuTabs from '../../features/form/components/form-menu-tabs';
import FormSummaryDragAndDrop from '../../features/form/components/form-summary-drag-and-drop';
import MissingDataDialog from '../../features/form/components/missing-data-dialog';
import { useFormContext } from '../../features/form/stores/form-context';
import { useFormModeContext } from '../../features/form/stores/form-mode-context';
import { stateToDto } from '../../models/InboundSpudLetterDto';
import ApiService from '../../services/api-service';
import { IAPIResponse } from '../../services/internal/ajax-service';
import { FormActions } from '../../stores/form/form-actions';
import { useLoaderContext } from '../../stores/loader-context';
import { AppState } from '../../stores/store';
import { NavbarState } from '../../stores/toolbar/toolbar-state';
import { useUserContext } from '../../stores/user-context';
import AppRoutes from '../../utils/AppRoutes';
import { FormMode } from '../../utils/FormMode';
import WindowToast from '../../utils/window-toast';
import FormPageInit from '../form-page-init/form-page-init';
import FormRouteContainer from '../form-route-container/FormRouteContainer';
import FormSummary from '../form-summary/form-summary';
import PendingChangesManager from '../pending-changes-manager/pending-changes-manager';
import { Head } from '../seo/head';
import { SpudLetterManagementToolbar } from '../spudletter-management-toolbar/SpudLetterManagementToolbar';

dayjs.extend(utc);

export function FormLayout() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { state } = useLocation();
    const { wellCode } = useParams();

    const { user: loggedUser } = useUserContext();
    const { formState: formState, setFormState: setFormState } = useFormContext();
    const { mode, setMode } = useFormModeContext();
    const loader = useLoaderContext();

    const attachments = useSelector((state: AppState) => state.attachments.attachments);

    const [step, setStep] = useState(() => {
        if (mode === FormMode.View) return 5;
        if (!isNaN(Number(state.step))) return Number(state.step);
        return 0;
    });

    const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);

    if (formState.wellCode && formState.locked && formState.lockedBy !== loggedUser.id && mode === FormMode.Edit) {
        setMode(FormMode.View);
        WindowToast.error('Spud Letter is locked');
        setStep(5);
    }
    //call update API
    const saveSpudLetter = (goToPreview: boolean) => {
        loader.show();
        ApiService.SpudLetterController.update(stateToDto(formState, attachments))
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw response.error;

                WindowToast.success('File saved successfully!');
                if (goToPreview) {
                    navigate(AppRoutes.DETAIL.replace(':wellCode', wellCode));
                }
            })
            .catch((err) => WindowToast.error('There was an error saving the file'))
            .finally(loader.hide);
    };

    useEffect(() => {
        if (formState.wellCode && mode === FormMode.View) {
            setStep(5);
        }
    }, [mode, formState.wellCode]);

    useEffect(() => {
        return () => {
            dispatch(
                FormActions.setMissingDataDialog({
                    show: false,
                    fields: [],
                }),
            );
        };
    }, []);

    return (
        <>
            <Head title={formState.wellCode} />
            <div className="spud-letter-form-wrap" id="form-wrap">
                <div id="top-element"> </div>
                {(mode === FormMode.Edit || mode === FormMode.ReviewedEdit) && <FormPageInit />}
                <SpudLetterManagementToolbar
                    type={NavbarState.SpudLetterForm}
                    currentFile={{
                        wellCode,
                        locked: formState.locked,
                        status: formState.status,
                        ownership: formState.ownership?.key,
                        signers: formState.signers,
                    }}
                />

                <div className="form-inner-section">
                    <div className="form-header">
                        <div className="form-title">Spud Letter</div>
                    </div>

                    <div className="form-content">
                        <section className="form-left-section">
                            <div className="form-section-title">DOCUMENT INFORMATION</div>

                            <FormSummary
                                enabledButtons={mode === FormMode.Edit || mode === FormMode.ReviewedEdit}
                                onDiscard={() => setShowDiscardModal(true)}
                                onSave={() => saveSpudLetter(false)}
                            />

                            <FormSummaryDragAndDrop wellCode={wellCode} mode={mode} />

                            <ActivityList />
                        </section>

                        <section className="form-right-section">
                            <div className="form-section-title">DETAILS</div>

                            {(mode === FormMode.Edit || mode === FormMode.ReviewedEdit) && (
                                <FormMenuTabs step={step} setStep={(step: number) => setStep(step)} />
                            )}

                            <FormRouteContainer
                                onSaveAndPreview={() => saveSpudLetter(true)}
                                currentStep={step}
                                increaseStep={() => setStep((prev) => prev + 1)}
                                decreaseStep={() => setStep((prev) => prev - 1)}
                                mode={mode}
                                onEditFromReview={(step: number) => {
                                    setStep(step);

                                    let mode = FormMode.View;
                                    if (formState.status === SpudLetterStatus.Draft) {
                                        mode = FormMode.Edit;
                                    }
                                    if (formState.status === SpudLetterStatus.ReviewedDraft) {
                                        mode = FormMode.ReviewedEdit;
                                    }
                                    setMode(mode);
                                }}
                            />

                            {mode === FormMode.Edit && <PendingChangesManager />}
                        </section>
                    </div>

                    <ModalDialog
                        modalTitle={'Discard current changes?'}
                        modalMessage={'The current changes will be lost'}
                        enableModal={showDiscardModal}
                        onAbort={() => setShowDiscardModal(false)}
                        onAccept={() => {
                            setShowDiscardModal(false);
                            navigate(AppRoutes.BASE);
                        }}
                    />

                    {mode === FormMode.Edit && (
                        <EditingTimeoutManager wellCode={formState.wellCode} key={formState.wellCode} />
                    )}

                    {mode === FormMode.Edit && <MissingDataDialog />}
                </div>
            </div>
        </>
    );
}
