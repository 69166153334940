import { useQuery } from '@tanstack/react-query';
import { QueryConfig } from '../../../lib/react-query';
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from '../../../services/internal/ajax-service';
import { Workflow } from '../../../models/Workflow';

export const getWorkflow = async ({ wellCode }): Promise<Workflow | null> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `/Workflow/${wellCode}/latest`
    let response: IAPIResponse = await AjaxService.call(request);
    return response.payload;
}

export const getWorkflowQueryOptions = (wellCode: string) => {
    return {
        queryKey: ['workflow', { wellCode }],
        queryFn: () => getWorkflow({ wellCode }),
    };
};

type UseWorkflowOptions = {
    wellCode: string
    queryConfig?: QueryConfig<typeof getWorkflowQueryOptions>;
};

export const useWorkflow = ({ wellCode, queryConfig }: UseWorkflowOptions) => {
    return useQuery({
        ...getWorkflowQueryOptions(wellCode),
        ...queryConfig,
        refetchInterval: false,
        staleTime: 0
    });
};
