import "./DocumentDetailColumn.scss";

import { LatLngExpression } from "leaflet";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";

import { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE_OPTIONS } from "../../utils/Constants";
import { getValueFromChoiceGroup } from "../../utils/Methods";
import { DetailBox } from "../detail-box/DetailBox";
import StatusBubble from "../status-bubble/StatusBubble";
import { DetailState } from "../../app/routes/detail";
import { useInputOptions } from "../../features/app/api/get-input-options";

export interface DocumentDetailColumnProps {
    state: DetailState;
}

const ChangeView = ({ center, zoom }: { center: LatLngExpression; zoom: number }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
};

const DocumentDetailColumn = (props: DocumentDetailColumnProps) => {
    const { state } = props;

    let center: LatLngExpression = {
        lat: isNaN(+state.coordinates?.latitude) ? 0 : +state.coordinates.latitude,
        lng: isNaN(+state.coordinates?.longitude) ? 0 : +state.coordinates.longitude,
    };
    const zoom = 7;

    const {
        data: { ownershipOptions, referenceSystemOptions },
    } = useInputOptions();

    const documentData = [
        {
            title: "Well Code",
            data: props.state.wellCode,
        },
        {
            title: "Well Name",
            data: props.state.wellName,
        },
        {
            title: "Tight",
            data: props.state.tight ? "Yes" : "No",
        },
        {
            title: "Last Edit",
            data: props.state.updateDate?.toLocaleString(DEFAULT_LOCALE, DEFAULT_LOCALE_DATE_OPTIONS),
        },
    ];
    const propertiesData = [
        {
            title: "Status",
            icon: "Completed",
            data: <StatusBubble documentStatus={props.state.status} isOldVersion={false} />,
        },
        {
            title: "Creation Date",
            icon: "Globe",
            data: props.state.creationDate?.toLocaleString(DEFAULT_LOCALE, DEFAULT_LOCALE_DATE_OPTIONS),
        },
        {
            title: "Status Date",
            icon: "SyncOccurence",
            data: "Not Implemented",
        },
        {
            title: "Ownership",
            icon: "FolderHorizontal",
            data: getValueFromChoiceGroup(ownershipOptions, props.state.ownership) ?? "--",
        },
        {
            title: "Reference System",
            icon: "NumberedList",
            data: getValueFromChoiceGroup(referenceSystemOptions, props.state.referenceSystem) ?? "--",
        },
    ];

    return (
        <div>
            <div className="detail-column-title">Detail</div>
            <DetailBox title={"Document"} data={documentData} />
            <DetailBox title={"Properties"} data={propertiesData} />

            <div className="leaflet-container">
                <MapContainer center={[0, 0]} zoom={zoom} scrollWheelZoom={false} dragging={false}>
                    <ChangeView center={center} zoom={zoom} />
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={center}>
                        <Popup>The current chose location</Popup>
                    </Marker>
                </MapContainer>
            </div>
        </div>
    );
};

export default DocumentDetailColumn;
