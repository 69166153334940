import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs, useLoaderData, useParams, useRouteError } from 'react-router-dom';
import { UserErrorPage } from '../../../components/user-error-page';
import { getLetterQueryOptions, useLetter } from '../../../features/detail/api/get-letter';
import { FormModeContextProvider } from '../../../features/form/stores/form-mode-context';
import FormContextProvider from '../../../features/form/stores/form-context';
import { FormLayout } from '../../../components/form-layout/form-layout';
import { getInputOptionsQueryOptions, useInputOptions } from '@/features/app/api/get-input-options';
import { getHydrocarbonPhasesQueryOptions, useHydrocarbonPhases } from '@/features/app/api/get-hydrocarbon-phases';
import { getInputInfosQueryOptions, useInputInfos } from '@/features/app/api/get-input-infos';
import { SpudLetterDto } from '@/models/SpudLetterDto';
import { InputInfo, InputOption } from '@/models/Options';
import { HydrocarbonPhase } from '@/models/ApiTypes';

export const formLoader =
    (queryClient: QueryClient) =>
    async ({ params }: LoaderFunctionArgs) => {
        const wellCode = params.wellCode as string;

        const letterQueryOptions = getLetterQueryOptions(wellCode);
        const inputOptionsQueryOptions = getInputOptionsQueryOptions();
        const inputInfosQueryOptions = getInputInfosQueryOptions();
        const hydrocarbonPhasesQueryOptions = getHydrocarbonPhasesQueryOptions();

        const promises = [
            queryClient.ensureQueryData(letterQueryOptions),
            queryClient.ensureQueryData(inputOptionsQueryOptions),
            queryClient.ensureQueryData(inputInfosQueryOptions),
            queryClient.ensureQueryData(hydrocarbonPhasesQueryOptions),
        ] as const;

        const [letter, inputOptions, inputInfos, hydrocarbonPhases] = await Promise.all(promises);

        return {
            letter,
            inputOptions,
            inputInfos,
            hydrocarbonPhases,
        };
    };

export const FormLayoutRoute = () => {
    const params = useParams();
    const wellCode = params.wellCode as string;
    // const { letter } = useLoaderData() as {
    //     letter: SpudLetterDto;
    //     inputOptions: InputOption[];
    //     inputInfos: InputInfo[];
    //     hydrocarbonPhases: HydrocarbonPhase[];
    // };

    const letterQuery = useLetter({ wellCode });

    return (
        <FormContextProvider letter={letterQuery.data}>
            <FormModeContextProvider>
                <FormLayout />
                {/* <Outlet /> */}
            </FormModeContextProvider>
        </FormContextProvider>
    );
};

export const FormLayoutErrorElement = () => {
    const error = useRouteError();
    console.error(error);

    if (error instanceof Error) {
        if (error.cause === 403) {
            return (
                <UserErrorPage title={'Unauthorized'} message={'You are not authorized to access this Spud Letter'} />
            );
        } else {
            return <UserErrorPage title={'Server Error'} message={'There was an error retrieving the Spud Letter'} />;
        }
    }

    return <UserErrorPage title={'Error'} message={'An unkonwn error occurred'} />;
};
