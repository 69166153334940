import { ColumnType } from '@/components/generic-list/GenericList';
import { EmailLog } from '../types/email-log';

export const emailLogsListColumns = [
    {
        name: 'Id',
        dataType: 'string',
        fieldName: 'id',
        size: 'medium',
    },
    {
        name: 'Location',
        dataType: 'string',
        fieldName: 'emailType',
        size: 'medium',
    },
    {
        name: 'TOs',
        dataType: 'string',
        fieldName: 'tos',
        size: 'medium',
    },
    {
        name: 'CCs',
        dataType: 'string',
        fieldName: 'tos',
        size: 'medium',
    },
    {
        name: 'BCCs',
        dataType: 'string',
        fieldName: 'tos',
        size: 'medium',
    },
    {
        name: 'Date',
        dataType: 'date',
        fieldName: 'date',
        size: 'medium',
    },
    {
        name: 'Message',
        dataType: 'string',
        fieldName: 'message',
        size: 'medium',
    },
] as ColumnType<EmailLog>[];
