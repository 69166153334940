export const errorLogsListColumns = [
    {
        name: "Id",
        dataType: "string",
        fieldName: "id",
        size: "medium",
    },
    {
        name: "Location",
        dataType: "string",
        fieldName: "procedureLocation",
        size: "medium",
    },
    {
        name: "Action",
        dataType: "string",
        fieldName: "procedureName",
        size: "medium",
    },
    {
        name: "Date",
        dataType: "string",
        fieldName: "date",
        size: "medium",
    },
    {
        name: "Message",
        dataType: "string",
        fieldName: "message",
        size: "medium",
    },
    {
        name: "Exception",
        dataType: "string",
        fieldName: "exception",
        size: "medium",
    },
];
