import ActivityController from "./controllers/activity-controller";
import AreaController from "./controllers/area-controller";
import DataOwnerController from "./controllers/data-owner-controller";
import DocumentController from "./controllers/document-controller";
import EditLockController from "./controllers/edit-lock-controller";
import ErrorLogsController from "./controllers/error-logs-controller";
import InboundController from "./controllers/inbound-controller";
import InputInfoController from "./controllers/input-info-controller";
import InputOptionsController from "./controllers/input-options-controller";
import LatestExternalDataController from "./controllers/latest-external-data-controller";
import RecipientController from "./controllers/recipient-controller";
import SpudLetterController from "./controllers/spudletter-controller";
import UserController from "./controllers/user-controller";
import WorkflowController from "./controllers/workflow-controller";

const ApiService = {
    SpudLetterController,
    InputInfoController,
    InputOptionsController,
    ActivityController,
    InboundController,
    UserController,
    DocumentController,
    AreaController,
    RecipientController,
    DataOwnerController,
    LatestExternalDataController,
    WorkflowController,
    EditLockController,
    ErrorLogsController,
};

export default ApiService;
