type FormContainer = {
    title: string;
    children: React.ReactNode;
};

export const FormContainer = ({ title, children }: FormContainer) => {
    return (
        <div className="form-summary-form-container">
            <div className="flex-row">
                <span className="form-section-title">{title}</span>
            </div>
            {children}
        </div>
    );
};
