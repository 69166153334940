import GenericList from "../../../components/generic-list/GenericList";
import { useErrorLogs } from "../api/get-error-logs";
import { errorLogsListColumns } from "./error-logs-list-columns";

const ErrorLogsList = () => {
    const errorLogsQuery = useErrorLogs();
    
    return (
        <GenericList
            allowFilter={true}
            searchText={"Search logs"}
            items={errorLogsQuery.data}
            columns={errorLogsListColumns}
        />
    );
};

export default ErrorLogsList;
