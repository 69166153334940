import {
    FluentUIDecorator,
    FluentUIDecoratorTypes,
} from '../../../../components/fluent-ui-decorator/FluentUIDecorator';
import { FormMode } from '../../../../utils/FormMode';
import { filterInputInfo, useInputInfos } from '../../../app/api/get-input-infos';
import { useFormContext } from '../../stores/form-context';
import { useFormModeContext } from '../../stores/form-mode-context';

const Notes = () => {
    const { formState, setFormState } = useFormContext();
    const { mode } = useFormModeContext();
    const isReviewedEdit = mode === FormMode.ReviewedEdit;

    const inputInfosQuery = useInputInfos();

    return (
        <div className="field-row">
            <div className="field-100">
                <FluentUIDecorator
                    label="Notes"
                    info={filterInputInfo(inputInfosQuery.data, 'notes', 'notes')}
                    fluentComponent={FluentUIDecoratorTypes.TextField({
                        disabled: isReviewedEdit,
                        placeholder: 'Please insert here any other additional information',
                        multiline: true,
                        rows: 10,
                        value: formState.notes,
                        onChange: (event, text) => setFormState((prev) => ({ ...prev, notes: text })),
                    })}
                />
            </div>
        </div>
    );
};

export default Notes;
