export interface SuspenseProps {
  fallback: JSX.Element;
  isLoading: boolean;
}

const Suspense: React.FC<SuspenseProps> = (props) => {
  if (props.isLoading) {
    return props.fallback;
  }
  return <>{props.children}</>;
};

export default Suspense;
