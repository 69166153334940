import { useEffect } from "react";

const DEFAULT_TITLE = "Spud Letter";

export const useDocumentHead = ({ title }: { title?: string | null }) => {

    useEffect(() => {
        let futureTitle = DEFAULT_TITLE

        if (title) {
            futureTitle = `${DEFAULT_TITLE} - ` + title;
        }

        document.title = futureTitle;
    }, [title])
}