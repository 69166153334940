import './UserRoles.scss';

import React from 'react';
import _ from 'underscore';

import { IPersonaProps } from '@fluentui/react';

import { useSelector } from 'react-redux';
import { filterInputInfo, useInputInfos } from '../../features/app/api/get-input-infos';
import { useFormContext } from '../../features/form/stores/form-context';
import { useFormModeContext } from '../../features/form/stores/form-mode-context';
import { IUser, personaToUser, userToPersonas } from '../../models/IUser';
import { WorkflowRole, WorkflowSigner } from '../../models/SpudLetterDto';
import { UserRole } from '../../models/UserRole';
import { GlobalState } from '../../stores/root-reducer';
import { FormMode } from '../../utils/FormMode';
import { FluentUIDecorator, FluentUIDecoratorTypes } from '../fluent-ui-decorator/FluentUIDecorator';
import { PeoplePicker } from '../people-picker/PeoplePicker';

const UserRoles = () => {
    const { formState: formState, setFormState: setFormState } = useFormContext();
    const { mode } = useFormModeContext();

    const inputInfosQuery = useInputInfos();

    const explorationManager = formState.signers.filter((x) => x.workflowRole === WorkflowRole.ExplorationManager);
    const explorationManagerDeputy = formState.signers.filter(
        (x) => x.workflowRole === WorkflowRole.ExplorationManagerDeputy,
    );
    const areaManager = formState.signers.filter((x) => x.workflowRole === WorkflowRole.AreaManager);
    const areaManagerDeputy = formState.signers.filter((x) => x.workflowRole === WorkflowRole.AreaManagerDeputy);
    const projectManager = formState.signers.filter((x) => x.workflowRole === WorkflowRole.ProjectManager);
    const projectManagerDeputy = formState.signers.filter((x) => x.workflowRole === WorkflowRole.ProjectManagerDeputy);

    const users = useSelector<GlobalState, IUser[]>((state) => state.form.users);
    const isReviewedEdit = mode === FormMode.ReviewedEdit;

    const writers = userToPersonas(
        users.filter((x) => x.roles.includes(UserRole.Writer) && x.roles.includes(UserRole.Approver)),
    );

    const approvers = userToPersonas(users.filter((x) => x.roles.includes(UserRole.Approver)));

    const filterWriters = async (filterText: string, currentPersonas: IPersonaProps[]) => {
        return writers
            .filter((x) => !formState.signers.some((s) => s.id === x.key))
            .filter((x) => x.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
    };

    const filterApprovers = async (filterText: string, currentPersonas: IPersonaProps[]) => {
        return approvers
            .filter((x) => !formState.signers.some((s) => s.id === x.key))
            .filter((x) => x.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
    };

    const setSigner = (user: IPersonaProps, role: WorkflowRole) => {
        setFormState((prev) => {
            if (!user) {
                //remove user
                return {
                    ...prev,
                    signers: prev.signers.filter((x) => x.workflowRole !== role),
                };
            }
            let signers = prev.signers;
            let newUser = { ...personaToUser(user), workflowRole: role } as WorkflowSigner;
            if (!signers.some((x) => x.workflowRole === role)) {
                // if not in users, add
                return {
                    ...prev,
                    signers: [...prev.signers, newUser],
                };
            }

            return {
                //substitute user
                ...prev,
                signers: prev.signers.map((x) => (x.workflowRole === role ? newUser : x)),
            };
        });
    };

    return (
        <>
            {(formState.ownership?.key === 'C' || formState.ownership?.key === 'O') && (
                <React.Fragment>
                    <span className="form-section-title">Project Manager</span>
                    <div className="field-row">
                        <div className="field-50 people-picker">
                            <PeoplePicker
                                label="Manager*"
                                selectedUsers={userToPersonas(projectManager)}
                                onFilterChanged={(filterText, currentPersonas, limitResults) =>
                                    filterApprovers(filterText, currentPersonas)
                                }
                                onPeopleChanged={(updatedPeople: IPersonaProps[]): void => {
                                    let user = _.first(updatedPeople) as IPersonaProps;
                                    setSigner(user, WorkflowRole.ProjectManager);
                                }}
                                itemLimit={1}
                            />
                        </div>
                        <div className="field-50 people-picker">
                            <PeoplePicker
                                label="Deputy"
                                selectedUsers={userToPersonas(projectManagerDeputy)}
                                disabled={isReviewedEdit}
                                onFilterChanged={(filterText, currentPersonas, limitResults) =>
                                    filterApprovers(filterText, currentPersonas)
                                }
                                onPeopleChanged={(updatedPeople: IPersonaProps[]): void => {
                                    let user = _.first(updatedPeople) as IPersonaProps;
                                    setSigner(user, WorkflowRole.ProjectManagerDeputy);
                                }}
                                itemLimit={1}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}

            {formState.ownership?.key === 'E' && (
                <React.Fragment>
                    <span className="form-section-title">Exploration Manager</span>

                    <div className="field-row" style={{ alignItems: 'end' }}>
                        <div className="field-50 people-picker">
                            <FluentUIDecorator
                                required
                                label={'Manager'}
                                info={filterInputInfo(inputInfosQuery.data, 'user-roles', 'exp-manager')}
                                fluentComponent={FluentUIDecoratorTypes.PeoplePicker({
                                    selectedUsers: userToPersonas(explorationManager),
                                    itemLimit: 1,
                                    disabled: isReviewedEdit,
                                    onPeopleChanged: (updatedPeople: IPersonaProps[]) => {
                                        let user = _.first(updatedPeople) as IPersonaProps;
                                        setSigner(user, WorkflowRole.ExplorationManager);
                                    },
                                    onFilterChanged: (filterText, currentPersonas, limitResults) =>
                                        filterWriters(filterText, currentPersonas),
                                })}
                            />
                        </div>
                        <div className="field-50 people-picker">
                            <PeoplePicker
                                label="Deputy"
                                selectedUsers={userToPersonas(explorationManagerDeputy)}
                                disabled={isReviewedEdit}
                                onFilterChanged={(filterText, currentPersonas, limitResults) =>
                                    filterWriters(filterText, currentPersonas)
                                }
                                onPeopleChanged={(updatedPeople: IPersonaProps[]): void => {
                                    let user = _.first(updatedPeople) as IPersonaProps;
                                    setSigner(user, WorkflowRole.ExplorationManagerDeputy);
                                }}
                                itemLimit={1}
                            />
                        </div>
                    </div>

                    <hr style={{ marginTop: '2em', marginBottom: '2rem' }} />
                    <span className="form-section-title">Area Manager</span>

                    <div className="field-row">
                        <div className="field-50 people-picker">
                            <PeoplePicker
                                label="Manager*"
                                selectedUsers={userToPersonas(areaManager)}
                                disabled={isReviewedEdit}
                                onFilterChanged={(filterText, currentPersonas, limitResults) =>
                                    filterApprovers(filterText, currentPersonas)
                                }
                                onPeopleChanged={(updatedPeople: IPersonaProps[]): void => {
                                    let user = _.first(updatedPeople) as IPersonaProps;
                                    setSigner(user, WorkflowRole.AreaManager);
                                }}
                                itemLimit={1}
                            />
                        </div>
                        <div className="field-50 people-picker">
                            <PeoplePicker
                                label="Deputy"
                                selectedUsers={userToPersonas(areaManagerDeputy)}
                                disabled={isReviewedEdit}
                                onFilterChanged={(filterText, currentPersonas, limitResults) =>
                                    filterApprovers(filterText, currentPersonas)
                                }
                                onPeopleChanged={(updatedPeople: IPersonaProps[]): void => {
                                    let user = _.first(updatedPeople) as IPersonaProps;
                                    setSigner(user, WorkflowRole.AreaManagerDeputy);
                                }}
                                itemLimit={1}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
        </>
    );
};

export default UserRoles;
