import { createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse } from "../internal/ajax-service";

const ErrorLogsController = {
    getErrorLogs: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = "/errorlogs";
        let response: IAPIResponse = await AjaxService.call(request);
        return response;
    },
};

export default ErrorLogsController;
