import { createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { FormMode } from "../../../utils/FormMode";

interface FormModeContextType {
    mode: FormMode;
    setMode: (mode: FormMode) => void;
}

export const FormModeContext = createContext<FormModeContextType>({} as FormModeContextType);

export const useFormModeContext = () => {
    return useContext(FormModeContext);
};

export const FormModeContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { state } = useLocation();
    const [mode, setMode] = useState<FormMode>(() => (!state ? FormMode.View : state.mode));

    // if (mode === FormMode.View && !pathname.endsWith(formRoutes[5])) {
    //     console.log("review");
    //     return <Navigate to={`../review`} replace />;
    // }
    // if (formRoutes.findIndex((x) => pathname.endsWith(x)) < 0) {
    //     console.log("general");
    //     return <Navigate to={`../general-information`} replace />;
    // }

    // console.log("all");
    return <FormModeContext.Provider value={{ mode, setMode }}>{children}</FormModeContext.Provider>;
};
