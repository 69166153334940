import './well-information.scss';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useContext } from 'react';
import _ from 'underscore';

import { Dropdown, IComboBox, IDropdownOption, ITag } from '@fluentui/react';
import DegreesMinutesSecondsInput from '../../../../components/degrees-minutes-seconds-input/DegreesMinutesSecondsInput';
import {
    FluentUIDecorator,
    FluentUIDecoratorTypes,
} from '../../../../components/fluent-ui-decorator/FluentUIDecorator';
import { FormSections } from '../../../../utils/FormSections';
import { Rig } from '../../../../models/ApiTypes';
import { CoordinateFormatType } from '../../../../models/CoordinateFormatType';
import { FormContext } from '../../stores/form-context';
import { FormMode } from '../../../../utils/FormMode';
import { getReferenceSystemAbbr } from '../../../../utils/Methods';
import { filterInputInfo, useInputInfos } from '../../../app/api/get-input-infos';
import { useInputOptions } from '../../../app/api/get-input-options';
import { getCRS } from '../../api/get-crs';
import { useRigs } from '../../api/get-rigs';
import { useMapCenter } from '../../hooks/use-map-center';
import { useFormModeContext } from '../../stores/form-mode-context';
import { MapWidget } from '../map-widget';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../../stores/root-reducer';

dayjs.extend(utc);

export interface RigState {
    rigId: string;
    rigName: string;
    contractorId: string;
    contractorName: string;
}

const WellInformation = () => {
    const { formState: formState, setFormState: setFormState } = useContext(FormContext);
    const { mode } = useFormModeContext();
    const inputInfosQuery = useInputInfos();

    const center = useMapCenter(
        formState.latitude,
        formState.longitude,
        formState.latDirection,
        formState.lonDirection,
    );

    const crsRequired = center.lat !== 0 || center.lng !== 0;

    const rigOptions = useSelector<GlobalState, Rig[]>((state) => state.form.rigs);

    const coordinateTypes = Object.keys(CoordinateFormatType)
        .filter((x) => isNaN(Number(x)))
        .map((x) => ({
            key: CoordinateFormatType[x],
            text: x.toString(),
        }));

    const {
        data: { currencyOptions, pathOptions, productionTypeOptions, wceiOptions },
    } = useInputOptions();

    const onSelectRig = (option: IDropdownOption) => {
        const rig = rigOptions.find((x) => x.rigname === option.key) as Rig;

        setFormState((prev) => ({
            ...prev,
            rig: rig.rigname,
            rigContractor: rig.contractor,
        }));
    };

    const onChangeTime = (_: React.FormEvent<IComboBox>, date: Date) => {
        setFormState((prev) => ({ ...prev, spudDate: date }));
    };

    const onChangeDate = (date: Date) => {
        setFormState((prev) => ({ ...prev, spudDate: date }));
    };

    const isProductionTypeDisabled = !(formState.ownership?.key === 'C' || formState.ownership?.key === 'O');

    // const mode = useSelector<GlobalState, FormMode>((state) => state.form.mode);
    const isReviewedEdit = mode === FormMode.ReviewedEdit;

    return (
        <>
            <div className="field-row">
                <div className="field-50">
                    <FluentUIDecorator
                        label="Spud date"
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'spud-date')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.DatePicker({
                            placeholder: 'Select',
                            value: formState.spudDate,
                            onSelectDate: onChangeDate,
                        })}
                    />
                </div>
                <div className="field-50">
                    <FluentUIDecorator
                        label="Spud time"
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'spud-time')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TimePicker({
                            allowFreeform: true,
                            increments: 1,
                            autoComplete: 'on',
                            onChange: onChangeTime,
                            defaultValue: formState.spudDate ?? new Date('January 1, 1970 00:00:00'),
                            useComboBoxAsMenuWidth: true,
                            placeholder: 'Select a time',
                        })}
                    />
                </div>
            </div>

            <div className="field-row">
                <div className="field-50">
                    <FluentUIDecorator
                        label="Production Type"
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'production-type')}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            disabled: isReviewedEdit || isProductionTypeDisabled,
                            placeholder: isProductionTypeDisabled ? 'N/a' : 'Select',
                            options: productionTypeOptions as IDropdownOption[],
                            selectedKey: isProductionTypeDisabled ? null : formState.productionType?.key,
                            onChange: (event, option) => setFormState((prev) => ({ ...prev, productionType: option })),
                        })}
                    />
                </div>
                <div className="field-50">
                    <FluentUIDecorator
                        label="Path"
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'path')}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            disabled: isReviewedEdit,
                            placeholder: 'Select',
                            options: pathOptions as IDropdownOption[],
                            selectedKey: formState.path?.key,
                            onChange: (event, option) => setFormState((prev) => ({ ...prev, path: option })),
                        })}
                    />
                </div>
            </div>

            <div className="field-row">
                <div className="field-50">
                    <FluentUIDecorator
                        label="Rig"
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'rig')}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            placeholder:
                                rigOptions?.length === 0
                                    ? formState.rig ?? ' No rigs were found for this well'
                                    : 'Select an option',
                            disabled: isReviewedEdit || rigOptions?.length === 0,
                            options: rigOptions.map((x) => ({
                                key: x.rigname,
                                text: x.rigname,
                            })),
                            selectedKey: formState.rig,
                            onChange: (event, option) => onSelectRig(option),
                        })}
                    />
                </div>
                <div className="field-50">
                    <FluentUIDecorator
                        label="Rig Contractor"
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'rig-contractor')}
                        required={false}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: true,
                            maxLength: 255,
                            placeholder: rigOptions?.length === 0 ? 'No rigs were found for this well' : '',
                            value: rigOptions.find((x) => x.contractor === formState.rigContractor)?.contractor,
                        })}
                    />
                </div>
            </div>

            <div className="field-row">
                <div className="field-50">
                    <FluentUIDecorator
                        label={`Water Depth/Ground Level [${getReferenceSystemAbbr(formState.referenceSystem)}]`}
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'water-depth')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            type: 'number',
                            maxLength: 255,
                            placeholder: 'e.g.: 1,79',
                            value: formState.waterDepth ?? '',
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, waterDepth: text })),
                        })}
                    />
                </div>
                <div className="field-50">
                    <FluentUIDecorator
                        label={`KB Elevation [${getReferenceSystemAbbr(formState.referenceSystem)}]`}
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'rotary-table')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            type: 'number',
                            maxLength: 255,
                            placeholder: 'e.g.: 9,71',
                            value: formState.rotaryTable ?? '',
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, rotaryTable: text })),
                        })}
                    />
                </div>
            </div>

            <hr style={{ marginTop: '2em', marginBottom: '2rem' }} />

            <span className="form-section-title">{FormSections.GEO_WELLHEAD_INFO.title}</span>

            <div className="field-row">
                <div className="field-50">
                    <div className="field-row">
                        <div className="field-50 ">
                            <FluentUIDecorator
                                label="Latitude"
                                info={filterInputInfo(inputInfosQuery.data, 'well-info', 'latitude')}
                                required={false}
                                errorMessage={(window as any)['highlight-errors'] === 1 ? 'Incorrect value' : undefined}
                                fluentComponent={FluentUIDecoratorTypes.TextField({
                                    disabled: formState.coordinateFormat !== CoordinateFormatType.DD,
                                    type: 'number',
                                    placeholder: 'ex 45,481551',
                                    value: formState.latitude ?? '',
                                    onChange: (event, text) => setFormState((prev) => ({ ...prev, latitude: text })),
                                })}
                            />
                        </div>
                        <div className="field-50">
                            <FluentUIDecorator
                                label="Longitude"
                                info={filterInputInfo(inputInfosQuery.data, 'well-info', 'longitude')}
                                required={false}
                                fluentComponent={FluentUIDecoratorTypes.TextField({
                                    disabled: formState.coordinateFormat !== CoordinateFormatType.DD,
                                    type: 'number',
                                    placeholder: 'ex 9,185632',
                                    value: formState.longitude ?? '',
                                    onChange: (event, text) => setFormState((prev) => ({ ...prev, longitude: text })),
                                })}
                            />
                        </div>
                    </div>

                    {formState.coordinateFormat !== CoordinateFormatType.DD && (
                        <div className="field-row">
                            <div className="field-50">
                                <DegreesMinutesSecondsInput
                                    defaultDecimalDegree={formState.latitude}
                                    setDecimalDegree={(value: string) => {
                                        setFormState((prev) => ({ ...prev, latitude: value }));
                                    }}
                                />
                            </div>
                            <div className="field-50 ">
                                <DegreesMinutesSecondsInput
                                    defaultDecimalDegree={formState.longitude}
                                    setDecimalDegree={(value: string) => {
                                        setFormState((prev) => ({ ...prev, longitude: value }));
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {formState.coordinateFormat !== CoordinateFormatType.DD && (
                        <span>Decimal degrees ex. N 45.481603° E 9.185757°</span>
                    )}
                    {formState.coordinateFormat !== CoordinateFormatType.DMS && (
                        <span>Degrees with minutes and decimal seconds ex. N 45° 28' 53.7708" E 9° 11' 8.72752"</span>
                    )}

                    <div className="field-row">
                        <div className="field-50">
                            <Dropdown
                                label="Latitude Direction"
                                options={[
                                    {
                                        key: 'N',
                                        text: 'North',
                                    },
                                    {
                                        key: 'S',
                                        text: 'South',
                                    },
                                ]}
                                selectedKey={formState.latDirection}
                                onChange={(event, option) => {
                                    setFormState((prev) => ({
                                        ...prev,
                                        latDirection: option.key.toString(),
                                    }));
                                }}
                            />
                        </div>
                        <div className="field-50">
                            <Dropdown
                                label="Longitude Direction"
                                options={[
                                    {
                                        key: 'E',
                                        text: 'East',
                                    },
                                    {
                                        key: 'W',
                                        text: 'West',
                                    },
                                ]}
                                selectedKey={formState.lonDirection}
                                onChange={(event, option) => {
                                    setFormState((prev) => ({
                                        ...prev,
                                        lonDirection: option.key.toString(),
                                    }));
                                }}
                            />
                        </div>
                    </div>

                    <div className="field-row">
                        <div className="field-50">
                            <FluentUIDecorator
                                label="Coordinates type"
                                info={filterInputInfo(inputInfosQuery.data, 'well-info', 'coordinate-type')}
                                required={true}
                                errorMessage={
                                    (window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined
                                }
                                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                                    placeholder: 'Select',
                                    options: coordinateTypes,
                                    selectedKey: formState.coordinateFormat,
                                    onChange: (event, option) =>
                                        setFormState((prev) => ({
                                            ...prev,
                                            coordinateFormat: +option.key,
                                        })),
                                })}
                            />
                        </div>
                        <div className="field-50">
                            <FluentUIDecorator
                                label="CRS"
                                info={filterInputInfo(inputInfosQuery.data, 'well-info', 'CRS')}
                                required={crsRequired}
                                errorMessage={
                                    (window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined
                                }
                                fluentComponent={FluentUIDecoratorTypes.TagPicker({
                                    // disabled: isReviewedEdit,
                                    pickerSuggestionsProps: {
                                        suggestionsHeaderText: 'Suggested CRS',
                                        noResultsFoundText: 'No CRS found',
                                    },
                                    inputProps: {
                                        placeholder: 'Please start typing a CRS name to get suggestions…',
                                    },
                                    onResolveSuggestions: function (
                                        filter: string,
                                        selectedItems?: ITag[],
                                    ): ITag[] | PromiseLike<ITag[]> {
                                        return getCRS(filter).then((res) =>
                                            res.map((x) => ({
                                                key: x.coord_ref_sys_cd,
                                                name: x.coord_ref_sys_name,
                                            })),
                                        );
                                    },
                                    onItemSelected: (selectItem: ITag) => selectItem,
                                    onChange: (items: ITag[]) => {
                                        setFormState((prev) => ({
                                            ...prev,
                                            coordinateReferenceSystem: items.length === 0 ? null : _.first(items),
                                        }));
                                    },
                                    resolveDelay: 500,
                                    itemLimit: 1,
                                    defaultSelectedItems: formState.coordinateReferenceSystem
                                        ? [formState.coordinateReferenceSystem]
                                        : [],
                                })}
                            />
                        </div>
                    </div>

                    <div className="field-row">
                        <div className="field-100">
                            <FluentUIDecorator
                                label="Additional Information"
                                info={filterInputInfo(inputInfosQuery.data, 'well-info', 'additional-information')}
                                fluentComponent={FluentUIDecoratorTypes.TextField({
                                    // disabled: isReviewedEdit,
                                    maxLength: 255,
                                    placeholder: 'e.g.: Datum, CRS Transform…',
                                    value: formState.coordinateAdditionalInfo ?? '',
                                    onChange: (event, text) =>
                                        setFormState((prev) => ({ ...prev, coordinateAdditionalInfo: text })),
                                })}
                            />
                        </div>
                    </div>
                </div>

                <MapWidget />
            </div>

            <hr style={{ marginTop: '2em', marginBottom: '2rem' }} />

            <div className="field-row">
                <div className="field-50">
                    <FluentUIDecorator
                        label={`Planned Total Depth/TVDSS [${getReferenceSystemAbbr(formState.referenceSystem)}]`}
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'tvdss')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            type: 'number',
                            maxLength: 255,
                            placeholder: 'e.g.: 3507,89',
                            value: formState.plannedTVDSS ?? '',
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, plannedTVDSS: text })),
                        })}
                    />
                </div>
                <div className="field-50">
                    <FluentUIDecorator
                        label={`Planned Total Depth/MD [${getReferenceSystemAbbr(formState.referenceSystem)}]`}
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'md')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            type: 'number',
                            maxLength: 255,
                            placeholder: 'e.g.: 3515',
                            value: formState.plannedMD ?? '',
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, plannedMD: text })),
                        })}
                    />
                </div>
            </div>
            <div className="field-row">
                <div className="field-33">
                    <FluentUIDecorator
                        label="AFE Cost"
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'afe-type')}
                        required={true}
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            disabled: isReviewedEdit,
                            placeholder: 'Select',
                            options: currencyOptions as IDropdownOption[],
                            selectedKey: formState.afeCostType?.key,
                            onChange: (event, option) => setFormState((prev) => ({ ...prev, afeCostType: option })),
                        })}
                    />
                </div>
                <div className="field-33">
                    <FluentUIDecorator
                        label=""
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'afe-cost')}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            type: 'number',
                            maxLength: 255,
                            placeholder: 'e.g.: 47,7 M$',
                            value: formState.afeCostValue ?? '',
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, afeCostValue: text })),
                        })}
                    />
                </div>
                <div className="field-33">
                    <FluentUIDecorator
                        label="AFE Days"
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'afe-days')}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            type: 'number',
                            maxLength: 255,
                            placeholder: 'e.g.: 35,4',
                            value: formState.afeDays ?? '',
                            onChange: (event, text) => setFormState((prev) => ({ ...prev, afeDays: text })),
                        })}
                    />
                </div>
            </div>
            <div className="field-row">
                <div className="field-100">
                    <FluentUIDecorator
                        label="WCEI"
                        info={filterInputInfo(inputInfosQuery.data, 'well-info', 'wcei')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            disabled: isReviewedEdit,
                            placeholder: 'Select',
                            options: wceiOptions as IDropdownOption[],
                            selectedKey: formState.wcei?.key,
                            onChange: (event, option) => {
                                setFormState((prev) => ({ ...prev, wcei: option }));
                            },
                        })}
                    />
                </div>
            </div>
        </>
    );
};

export default WellInformation;
