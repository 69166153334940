import { useEffect } from 'react';
import _ from 'underscore';

import { useDispatch } from 'react-redux';
import { useFormContext } from '../../features/form/stores/form-context';
import { useFormModeContext } from '../../features/form/stores/form-mode-context';
import { CountryReservoirLevel, IArisField, Prospect, Rig } from '../../models/ApiTypes';
import { IUser } from '../../models/IUser';
import { SpudLetterDto } from '../../models/SpudLetterDto';
import ApiService from '../../services/api-service';
import { IAPIResponse } from '../../services/internal/ajax-service';
import { FormActions } from '../../stores/form/form-actions';
import { useLoaderContext } from '../../stores/loader-context';
import { FormMode } from '../../utils/FormMode';
import WindowToast from '../../utils/window-toast';

const FormPageInit = () => {
    const { formState, setFormState } = useFormContext();
    const { mode } = useFormModeContext();

    const dispatch = useDispatch();

    const loader = useLoaderContext();

    const notifications = formState.missingDataNotifications;

    const checkMissingField = (
        payload: any[],
        propertyName: keyof SpudLetterDto,
        propertyName2?: keyof SpudLetterDto,
    ) => {
        if (mode === FormMode.Edit && payload.length === 0) {
            let notification = notifications.filter((x) => x.field === propertyName);
            if (_.isEmpty(notification) || !_.first(notification)!.notificationSent) {
                let fields = [propertyName];
                if (propertyName2) fields.push(propertyName2);
                dispatch(
                    FormActions.setMissingDataDialog({
                        show: true,
                        fields,
                    }),
                );
            }
        }
    };

    const getArisFields = () => {
        loader.show();
        ApiService.InboundController.getArisFields(formState.country.key.toString())
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw 'Error retrieving aris fields!';
                let arisFields = response.payload as IArisField[];
                if (formState.ownership?.key !== 'E') {
                    checkMissingField(arisFields, 'arisField');
                }
                dispatch(FormActions.setArisFields(arisFields));
            })
            .catch(WindowToast.error)
            .finally(loader.hide);
    };

    const getProspects = () => {
        loader.show();
        ApiService.InboundController.getProspects(formState.country.key.toString())
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw 'Error retrieving prospects';
                let prospects = response.payload as Prospect[];
                checkMissingField(prospects, 'prospect');
                dispatch(FormActions.setProspects(prospects));
            })
            .catch(WindowToast.error)
            .finally(loader.hide);
    };

    const getReservoirFields = () => {
        loader.show();
        ApiService.InboundController.getCountryReservoirLevels(undefined, formState.country.key.toString())
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw 'Error retrieving reservoir fields';
                let reservoirFields = response.payload as CountryReservoirLevel[];
                if (formState.ownership?.key !== 'E') {
                    checkMissingField(reservoirFields, 'reservoirField');
                }
                dispatch(FormActions.setReservoirOptions(reservoirFields));
            })
            .catch(WindowToast.error)
            .finally(loader.hide);
    };

    const getRigs = () => {
        loader.show();
        ApiService.InboundController.getRigContractors(formState.wellCode)
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw response.error;
                let rigs = response.payload as Rig[];
                checkMissingField(rigs, 'rig', 'rigContractor');
                if (formState.rig !== null && formState.rig !== '' && !rigs.some((x) => x.rigname === formState.rig))
                    rigs.push({
                        wellida: formState.wellCode,
                        wellName: formState.wellName,
                        contractor: formState.rigContractor,
                        rigname: formState.rig,
                    });

                dispatch(FormActions.setRigs(rigs));
            })
            .catch((err) => WindowToast.error('Error retrieving rigs'))
            .finally(loader.hide);
    };

    const getUsers = () => {
        loader.show();
        ApiService.UserController.getCountryTightUsers(formState.wellCode, formState.country?.key?.toString())
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw response.error;
                let payload = response.payload as IUser[];
                dispatch(FormActions.setUsers(payload));
            })
            .catch((err) => WindowToast.error('There was an error loading the users'))
            .finally(loader.hide);
    };

    useEffect(() => {
        if (formState.arisField === null) getReservoirFields();
    }, [formState.arisField]);

    useEffect(() => {
        getRigs();
        getArisFields();
        getProspects();
        getReservoirFields();
        getUsers();
    }, []);

    return null;
};

export default FormPageInit;
