import { useQuery } from '@tanstack/react-query';
import { QueryConfig } from '../../../lib/react-query';
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from '../../../services/internal/ajax-service';
import { InputInfo } from '../../../models/Options';

export const getInputInfos = async (): Promise<InputInfo[]> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = "/InputInfo"
    let response: IAPIResponse = await AjaxService.call(request);
    return response.payload;
}

export const getInputInfosQueryOptions = () => {
    return {
        queryKey: ['input-infos'],
        queryFn: () => getInputInfos(),
    };
};

type UseInputInfosOptions = {
    queryConfig?: QueryConfig<typeof getInputInfosQueryOptions>;
};

export const useInputInfos = ({ queryConfig }: UseInputInfosOptions = {}) => {
    return useQuery({
        ...getInputInfosQueryOptions(),
        ...queryConfig,
        placeholderData: [],
        staleTime: Infinity
    });
};

export const filterInputInfo = (
    inputInfos: InputInfo[],
    page: string,
    key: string
): string => {
    return inputInfos.find(x => x.page === page && x.key === key)?.message;
};