import { api } from "@/lib/api-client";
import { MutationConfig } from "@/lib/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getWorkflowQueryOptions } from "./get-workflow";
import { getLetterQueryOptions } from "./get-letter";

export const startWorkflow = async (wellCode: string): Promise<any> => {
    return api.post(`/spudletter/${wellCode}/start-workflow`,)
};

type UseStartWorkflowOptions = {
    wellCode: string
    mutationConfig?: MutationConfig<typeof startWorkflow>;
};

export const useStartWorkflow = ({ mutationConfig, wellCode }: UseStartWorkflowOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, onError, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (data, ...args) => {
            queryClient.invalidateQueries(getWorkflowQueryOptions(wellCode));
            queryClient.invalidateQueries(getLetterQueryOptions(wellCode));
            onSuccess?.(data, ...args);
        },
        onError: (error, ...args) => {
            onError?.(error, ...args);
        },
        ...restConfig,
        mutationFn: startWorkflow,
    });
};
