import { getEmailLogsQueryOptions } from '@/features/email-logs/api/get-email-logs';
import EmailLogsList from '@/features/email-logs/components/email-logs-list';
import { QueryClient } from '@tanstack/react-query';

export const emailLogsLoader = (queryClient: QueryClient) => async () => {
    const query = getEmailLogsQueryOptions();

    return await queryClient.ensureQueryData(query);
};

export const EmailLogsRoute = () => {
    return <EmailLogsList />;
};
