import { useQuery } from '@tanstack/react-query';
import { IActivity } from '../../../components/activity-column/activity-column';
import { api } from '../../../lib/api-client';
import { QueryConfig } from '../../../lib/react-query';

export const getUserActivities = async (): Promise<IActivity[]> => {
    return api.get("/activities/user")
}

export const getUserActivitiesQueryOptions = () => {
    return {
        queryKey: ['user-activities'],
        queryFn: () => getUserActivities(),
    };
};

type UseUserActivitiesOptions = {
    queryConfig?: QueryConfig<typeof getUserActivitiesQueryOptions>;
};

export const useUserActivities = ({ queryConfig }: UseUserActivitiesOptions = {}) => {
    return useQuery({
        ...getUserActivitiesQueryOptions(),
        ...queryConfig,
        placeholderData: []
    });
};
