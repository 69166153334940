import Loader from '@/components/loader/loader-component';
import { QueryClient, useIsMutating } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import SpudLetterUserMenu from '../../../components/user-menu/user-menu';
import { getInputInfosQueryOptions } from '../api/get-input-infos';
import { getInputOptionsQueryOptions } from '../api/get-input-options';
import { useXwareHealthCheck } from '../api/get-xware-health-check';
import { useEffect } from 'react';
import WindowToast from '@/utils/window-toast';

export const appLayoutLoader = (queryClient: QueryClient) => async () => {
    const inputOptionsQueryOptions = getInputOptionsQueryOptions();
    const inputInfosQueryOptions = getInputInfosQueryOptions();

    const promises = [
        queryClient.ensureQueryData(inputOptionsQueryOptions),
        queryClient.ensureQueryData(inputInfosQueryOptions),
    ] as const;

    const [inputOptionsQuery, inputInfosQuery] = await Promise.all(promises);

    return {
        inputOptionsQuery,
        inputInfosQuery,
    };
};

export const AppLayout = () => {
    const value = useIsMutating();

    const xwareHealthCheck = useXwareHealthCheck();

    useEffect(() => {
        if (xwareHealthCheck.data && xwareHealthCheck.data.status !== 'Healthy') {
            WindowToast.error(xwareHealthCheck.data.message);
        }
    }, [xwareHealthCheck.data]);

    return (
        <div style={{ overflow: 'hidden' }}>
            {value > 0 && <Loader display />}
            <SpudLetterUserMenu />
            <Outlet />
        </div>
    );
};
