import { api } from '@/lib/api-client';
import { useQuery } from '@tanstack/react-query';
import { QueryConfig } from '../../../lib/react-query';
import { HealthStatus } from '../types/health-status';

export const getXwareHealthCheck = async (): Promise<HealthStatus> => {
    return api.get<HealthStatus>('/inbound/xware-health-check');
};

export const getXwareHealthCheckQueryOptions = () => {
    return {
        queryKey: ['xware-health-check'],
        queryFn: () => getXwareHealthCheck(),
    };
};

type UseXwareHealthCheckOptions = {
    queryConfig?: QueryConfig<typeof getXwareHealthCheckQueryOptions>;
};

export const useXwareHealthCheck = ({ queryConfig }: UseXwareHealthCheckOptions = {}) => {
    return useQuery({
        ...getXwareHealthCheckQueryOptions(),
        ...queryConfig,
        staleTime: 0,
        suspense: false
    });
};
