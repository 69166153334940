import './general-information.scss';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { FluentUIDecorator, FluentUIDecoratorTypes } from '@/components/fluent-ui-decorator/FluentUIDecorator';
import { filterInputInfo, useInputInfos } from '@/features/app/api/get-input-infos';
import { useInputOptions } from '@/features/app/api/get-input-options';
import { useFormContext } from '@/features/form/stores/form-context';
import { useFormModeContext } from '@/features/form/stores/form-mode-context';
import { propertyToFieldNameMap } from '@/models/FormRoutesStates';
import { FormMode } from '@/utils/FormMode';
import { FormSections } from '@/utils/FormSections';
import { IChoiceGroupOption, ITag } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { defaultNS } from '@/config/i18n';

dayjs.extend(utc);

const GeneralInformation = () => {
    const { formState, setFormState } = useFormContext();
    const { mode } = useFormModeContext();
    const { t } = useTranslation(defaultNS);

    const inputOptionsQuery = useInputOptions();
    const inputInfosQuery = useInputInfos();

    const { referenceSystemOptions, ownershipOptions, onshoreOptions } = inputOptionsQuery.data;
    const isReviewedEdit = mode === FormMode.ReviewedEdit;

    return (
        <>
            <div className="form-grid">
                <div className="grid-columns-3">
                    <FluentUIDecorator
                        label="Place"
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'place')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            maxLength: 255,
                            placeholder: t('placeholder:place'),
                            value: formState.place ?? '',
                            onChange: (event, text) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    place: text,
                                })),
                        })}
                    />
                </div>
                <div className="grid-columns-3">
                    <FluentUIDecorator
                        label="Date"
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'date')}
                        fluentComponent={FluentUIDecoratorTypes.DatePicker({
                            placeholder: 'Select',
                            value: formState.date,
                            onSelectDate: (date) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    date: date,
                                })),
                        })}
                    />
                </div>

                <div className="grid-columns-6">
                    <FluentUIDecorator
                        label="From"
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'from')}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            maxLength: 255,
                            placeholder: t('placeholder:from'),
                            value: formState.from ?? '',
                            onChange: (event, text) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    from: text,
                                })),
                        })}
                    />
                </div>

                <div className="grid-columns-3">
                    <FluentUIDecorator
                        label="Well code"
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'well-code')}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            maxLength: 255,
                            disabled: true,
                            value: formState.wellCode,
                            onChange: (event, text) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    wellCode: text,
                                })),
                        })}
                    />
                </div>

                <div className="grid-columns-3">
                    <FluentUIDecorator
                        label="Well Name"
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'well-name')}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: true,
                            maxLength: 255,
                            value: formState.wellName,
                            onChange: (event, text) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    wellName: text,
                                })),
                        })}
                    />
                </div>

                <div className="grid-columns-3">
                    <FluentUIDecorator
                        label="Reference System"
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'reference-system')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.ChoiceGroup({
                            disabled: isReviewedEdit,
                            options: referenceSystemOptions as IChoiceGroupOption[],
                            selectedKey: formState.referenceSystem?.key,
                            onChange: (event, option) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    referenceSystem: option,
                                })),
                            styles: choiceGroupHorizontalStyles,
                        })}
                    />
                </div>

                <div className="grid-columns-3">
                    <FluentUIDecorator
                        label="Ownership"
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'ownership')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.ChoiceGroup({
                            disabled: isReviewedEdit,
                            options: ownershipOptions,
                            selectedKey: formState.ownership?.key,
                            onChange: (event, option) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    ownership: option,
                                    signers: [],
                                })),
                            styles: choiceGroupHorizontalStyles,
                        })}
                        className="grid-columns-3"
                    />
                </div>
            </div>

            <hr style={{ marginTop: '2em', marginBottom: '2rem' }} />

            <span className="form-section-title">{FormSections.GENERAL_INFO.title}</span>

            <div className="form-grid">
                <div className="grid-columns-3">
                    <FluentUIDecorator
                        label="Country"
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'country')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TagPicker({
                            disabled: true,
                            pickerSuggestionsProps: {
                                suggestionsHeaderText: 'Suggested countries',
                                noResultsFoundText: 'No country found',
                            },
                            onResolveSuggestions: () => [],
                            onChange: () => {},
                            itemLimit: 1,
                            defaultSelectedItems: formState.country ? [formState.country] : [],
                        })}
                    />
                </div>
                <div className="grid-columns-3">
                    <FluentUIDecorator
                        label={propertyToFieldNameMap['blockName']}
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'block-name')}
                        required
                        errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            maxLength: 255,
                            value: formState.blockName,
                            onChange: (event, text) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    blockName: text,
                                })),
                        })}
                    />
                </div>

                <div className="grid-columns-3">
                    <FluentUIDecorator
                        label={t('translation:operator')}
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'operator')}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            multiline: false,
                            autoAdjustHeight: false,
                            rows: 5,
                            // maxLength: 255,
                            value: formState.operator,
                            onChange: (event, text) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    operator: text,
                                })),
                        })}
                    />
                </div>

                <div className="grid-columns-3 grid-rows-2">
                    <FluentUIDecorator
                        label="Partnership (Participating Interest)"
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'partnership')}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            disabled: isReviewedEdit,
                            multiline: true,
                            autoAdjustHeight: true,
                            rows: 5,
                            // maxLength: 255,
                            value: formState.partnerships,
                            onChange: (event, text) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    partnerships: text,
                                })),
                        })}
                    />
                </div>
                <div className="grid-columns-1">
                    <FluentUIDecorator
                        label={t('translation:pi')}
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'participating-interest')}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            type: 'number',
                            disabled: isReviewedEdit,
                            multiline: false,
                            autoAdjustHeight: false,
                            value: formState.participatingInterest,
                            suffix: '%',
                            placeholder: '0',
                            onChange: (event, text) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    participatingInterest: text,
                                })),
                        })}
                    />
                </div>
                <div className="grid-columns-1">
                    <FluentUIDecorator
                        label={t('translation:wi')}
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'working-interest')}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                            type: 'number',
                            disabled: isReviewedEdit,
                            multiline: false,
                            autoAdjustHeight: false,
                            value: formState.workingInterest,
                            suffix: '%',
                            placeholder: '0',
                            onChange: (event, text) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    workingInterest: text,
                                })),
                        })}
                    />
                </div>

                <div className="grid-columns-3">
                    <FluentUIDecorator
                        label="Eni operated"
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'eni-operated')}
                        fluentComponent={FluentUIDecoratorTypes.Toggle({
                            disabled: isReviewedEdit,
                            checked: formState.eniOperated,
                            onChange: (event, checked) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    eniOperated: checked,
                                })),
                        })}
                    />
                </div>
                <div className="grid-columns-3">
                    <FluentUIDecorator
                        label="Onshore/Offshore"
                        info={filterInputInfo(inputInfosQuery.data, 'general-info', 'onshore-offshore')}
                        fluentComponent={FluentUIDecoratorTypes.ChoiceGroup({
                            disabled: isReviewedEdit,
                            options: onshoreOptions,
                            selectedKey: formState.onshoreOffshore?.key,
                            onChange: (event, option) =>
                                setFormState((prev) => ({
                                    ...prev,
                                    onshoreOffshore: option,
                                })),
                            styles: choiceGroupHorizontalStyles,
                        })}
                    />
                </div>
            </div>
        </>
    );
};

export default GeneralInformation;

const choiceGroupHorizontalStyles = {
    flexContainer: { display: 'flex', flexDirection: 'row', gridGap: '1em' },
};
