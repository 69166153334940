import './form-summary.scss';

import { useContext } from 'react';
import _ from 'underscore';

import { Icon, Toggle } from '@fluentui/react';

import { FormContext } from '../../features/form/stores/form-context';
import { WorkflowRole } from '../../models/SpudLetterDto';
import { SpudLetterStatus } from '../../models/SpudLetterStatus';
import { UserRole } from '../../models/UserRole';
import { useLoaderContext } from '../../stores/loader-context';
import { useUserContext } from '../../stores/user-context';
import { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE_OPTIONS } from '../../utils/Constants';
import LabelButton from '../label-button/label-button';

const FormSummary = ({
    enabledButtons,
    onDiscard,
    onSave,
}: {
    enabledButtons: boolean;
    onDiscard: () => void;
    onSave: () => void;
}) => {
    const { formState, setFormState } = useContext(FormContext);
    const { user: currentUser } = useUserContext();
    const loader = useLoaderContext();

    const isEditable =
        (formState.status === SpudLetterStatus.Draft || formState.status === SpudLetterStatus.ReviewedDraft) &&
        currentUser?.roles?.some((x) => x === UserRole.Writer) &&
        !formState.locked;

    const isTight = formState.xwareTight || formState.userTight;

    return (
        <>
            <div className="grid">
                <GridItem title={'Well Code'} content={formState.wellCode} />
                <GridItem title={'Well name'} content={formState.wellName} />
                <GridItem
                    title={'Tight'}
                    content={
                        <div style={{ display: 'flex', gap: '1em' }}>
                            <Toggle
                                checked={isTight}
                                onChange={(event, checked) =>
                                    setFormState((prev) => ({
                                        ...prev,
                                        userTight: checked,
                                    }))
                                }
                                disabled={formState.xwareTight || !isEditable}
                            />
                            {isTight ? 'Yes' : 'No'}
                        </div>
                    }
                />
            </div>
            <div className="grid">
                <GridItem
                    title={
                        <>
                            <Icon iconName="Calendar" /> Created
                        </>
                    }
                    content={formState.creationDate?.toLocaleString(DEFAULT_LOCALE, DEFAULT_LOCALE_DATE_OPTIONS)}
                />
                <GridItem
                    title={
                        <>
                            <Icon iconName="SyncOccurence" /> Update
                        </>
                    }
                    content={formState.updateDate?.toLocaleString(DEFAULT_LOCALE, DEFAULT_LOCALE_DATE_OPTIONS)}
                />
                <GridItem
                    title={
                        <>
                            <Icon iconName="BullseyeTarget" /> Ownership
                        </>
                    }
                    content={formState.ownership?.text}
                />
                <GridItem
                    title={
                        <>
                            <Icon iconName="NumberedList" /> Reference System
                        </>
                    }
                    content={formState.referenceSystem?.text}
                />
                {(formState.ownership?.key === 'C' || formState.ownership?.key === 'O') && (
                    <GridItem
                        title={'Project Manager'}
                        content={
                            _.first(formState.signers.filter((x) => x.workflowRole === WorkflowRole.ProjectManager))
                                ?.name
                        }
                    />
                )}
                {formState.ownership?.key === 'E' && (
                    <>
                        <GridItem
                            title={'Exploration Manager'}
                            content={
                                _.first(
                                    formState.signers.filter((x) => x.workflowRole === WorkflowRole.ExplorationManager),
                                )?.name
                            }
                        />
                        <GridItem
                            title={'Area Manager'}
                            content={
                                _.first(formState.signers.filter((x) => x.workflowRole === WorkflowRole.AreaManager))
                                    ?.name
                            }
                        />
                    </>
                )}
                {enabledButtons && <hr style={{ gridColumn: 'span 2', width: '100%' }} />}
                {enabledButtons && (
                    <LabelButton text="Discard" redOutlined disabled={loader.isLoading} onClick={onDiscard} fullWidth />
                )}
                {enabledButtons && (
                    <LabelButton
                        text="Save"
                        orangeSolid
                        icon="Save"
                        disabled={loader.isLoading}
                        fullWidth
                        onClick={onSave}
                    />
                )}
            </div>
        </>
    );
};

export default FormSummary;

interface GridItemProps {
    title: string | JSX.Element;
    content: string | JSX.Element;
}

const GridItem = ({ title, content }: GridItemProps) => {
    return (
        <div className="grid-item">
            <div>{title}</div>
            <div>{content === undefined ? '--' : content}</div>
        </div>
    );
};
