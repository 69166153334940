import GenericList from '../../../components/generic-list/GenericList';
import { useEmailLogs } from '../api/get-email-logs';
import { emailLogsListColumns } from './email-logs-list-columns';

const EmailLogsList = () => {
    const emailLogsQuery = useEmailLogs();

    return (
        <GenericList
            allowFilter={true}
            searchText={'Search logs'}
            items={emailLogsQuery.data}
            columns={emailLogsListColumns}
        />
    );
};

export default EmailLogsList;
