import { Link } from "react-router-dom";

import { Icon } from "@fluentui/react";
import { SpudLetterItem } from "../../../models/SpudLetterItem";
import AppRoutes from "../../../utils/AppRoutes";
import { getValueFromChoiceGroup } from "../../../utils/Methods";
import { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE_TIME_OPTIONS } from "../../../utils/Constants";
import StatusBubble from "../../../components/status-bubble/StatusBubble";
import { Option } from "../../../models/Options";

export const SpudLetterListColumns = (ownershipOptions: Option[]) => [
    {
        name: "Well Name",
        dataType: null,
        fieldName: null,
        iconName: null,
        size: "medium",
        onRender: (item: SpudLetterItem) => {
            return (
                <Link className="document-link" to={AppRoutes.DETAIL.replace(":wellCode", item.wellCode)}>
                    {item.wellName}
                </Link>
            );
        },
    },
    {
        name: "Well Code",
        dataType: "string",
        fieldName: "wellCode",
        iconName: null,
        size: "small",
    },
    {
        name: "Country",
        dataType: "string",
        fieldName: "country.countryDescription",
        iconName: null,
        size: "small",
    },
    {
        name: "Ownership",
        dataType: "string",
        fieldName: "ownership",
        iconName: null,
        size: "medium",
        onRender: (item: SpudLetterItem) => {
            return !ownershipOptions ? <div>Loading</div> : getValueFromChoiceGroup(ownershipOptions, item.ownership);
        },
    },
    {
        name: "Eni Operated",
        dataType: null,
        fieldName: null,
        iconName: null,
        size: "small",
        onRender: (item: SpudLetterItem) => {
            return item.eniOperated ? <Icon iconName="CheckMark" /> : <Icon iconName="Cancel" />;
        },
    },
    {
        name: "Spud Date",
        dataType: null,
        fieldName: null,
        iconName: null,
        size: "medium",
        onRender: (item: SpudLetterItem) => {
            return !!item.spudDate
                ? item.spudDate.toLocaleString(DEFAULT_LOCALE, DEFAULT_LOCALE_DATE_TIME_OPTIONS)
                : "Not set";
        },
    },
    {
        name: "Status",
        dataType: null,
        fieldName: null,
        iconName: null,
        size: "medium",
        onRender: (item: SpudLetterItem) => {
            return <StatusBubble documentStatus={item.status} isOldVersion={false} />;
        },
    },
];
