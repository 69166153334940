import { api } from "@/lib/api-client";
import { MutationConfig } from "@/lib/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getLetterQueryOptions } from "./get-letter";
import { SpudLetterStatus } from "@/models/SpudLetterStatus";

export const deAuthorize = async ({ wellCode, targetStatus }): Promise<any> => {
    return api.post(`/spudletter/${wellCode}/de-authorize?targetStatus=${targetStatus}`)
};

type UseDeAuthorizeOptions = {
    wellCode: string
    targetStatus: SpudLetterStatus
    mutationConfig?: MutationConfig<typeof deAuthorize>;
};

export const useDeAuthorize = ({
    mutationConfig,
    wellCode,
    targetStatus
}: UseDeAuthorizeOptions) => {
    const queryClient = useQueryClient();

    const { onSuccess, onError, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (data, ...args) => {
            queryClient.invalidateQueries({
                queryKey: getLetterQueryOptions(wellCode).queryKey,
            });
            onSuccess?.(data, ...args);
        },
        onError: (error, ...args) => {
            onError?.(error, ...args);
        },
        ...restConfig,
        mutationFn: deAuthorize,
    });
};
