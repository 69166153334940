import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FileInfo } from '../../../models/FileUtils'

type AttachmentsSliceState = {
  attachments: FileInfo[]
}

const initialState: AttachmentsSliceState = {
  attachments: []
}

const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    setAttachments(state, action: PayloadAction<FileInfo[]>) {
      state.attachments = action.payload
    },
    addAttachment(state, action: PayloadAction<FileInfo>) {
      state.attachments = [...state.attachments, action.payload]
    },
    updateCategory(state, action: PayloadAction<{ id: string, category: string }>) {
      state.attachments = state.attachments.map((att) => att.id === action.payload.id ? { ...att, category: action.payload.category } : att)
    },
    deleteAttachment(state, action: PayloadAction<{ id: string }>) {
      state.attachments = state.attachments.filter((att) => att.id !== action.payload.id)
    },
  }
})

export const { setAttachments, addAttachment, updateCategory, deleteAttachment } = attachmentsSlice.actions

export default attachmentsSlice.reducer