import { useState } from "react";

type UseBooleanFunction = (initial: boolean) => [boolean, UseBooleanActions];

export type UseBooleanActions = {
    setValue: React.Dispatch<React.SetStateAction<boolean>>;
    toggle: VoidFunction;
    setTrue: VoidFunction;
    setFalse: VoidFunction;
};

export const useBoolean: UseBooleanFunction = (initialValue: boolean) => {
    const [value, setValue] = useState<boolean>(initialValue);

    const toggle = () => {
        setValue((prev) => !prev);
    };
    const setTrue = () => {
        setValue(true);
    };
    const setFalse = () => {
        setValue(false);
    };

    return [value, { setValue, toggle, setTrue, setFalse }];
};