import { PropsWithChildren, createContext, useContext, useState } from "react";
import Loader from "../components/loader/loader-component";

export interface ILoaderContext {
    show: () => void;
    hide: () => void;
    reset: () => void;
    isLoading: boolean;
}

const LOADER_CONTEXT_DEFAULT = {
    show: () => {},
    hide: () => {},
    reset: () => {},
    isLoading: false,
};
export const LoaderContext = createContext<ILoaderContext>(LOADER_CONTEXT_DEFAULT);
export const useLoaderContext = () => useContext(LoaderContext);

export const LoaderContextProvider = (props: PropsWithChildren<{}>) => {
    const [loaderDepth, setLoaderDepth] = useState(0);
    const loaderContextValue: ILoaderContext = {
        show: () => setLoaderDepth((depth) => depth + 1),
        hide: () => setLoaderDepth((depth) => (depth > 0 ? depth - 1 : 0)),
        reset: () => setLoaderDepth(0),
        isLoading: loaderDepth > 0,
    };

    return (
        <LoaderContext.Provider value={loaderContextValue}>
            <Loader display={loaderDepth > 0} />
            {props.children}
        </LoaderContext.Provider>
    );
};
