import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { DragAndDropContainerInner } from "../drag-and-drop-container-inner/drag-and-drop-container-inner";
import "./drag-and-drop-container.scss";

export interface DragAndDropContainerProps {
    icon: JSX.Element | null;
    title: JSX.Element | null;
    lowerElement: JSX.Element | null;
    onDrop: (files: File[]) => any;
    allowFileListing: boolean;
    topOfListView?: React.ReactNode;
    customListView?: (items: any[]) => JSX.Element[];
    preLoadFiles?: any[];
    enabled?: boolean;
    multiple: boolean;
    disableInternalListing: boolean;
    wrapperClass?: string;
}

const MAX_FILE_SIZE = 750_000_000; // 750 MB !

export const DragAndDropContainer = (props: DragAndDropContainerProps) => {
    const fileId: string = uuid();

    const [files, setFiles] = useState<File[]>([]);
    const [preview, setPreview] = useState<any[]>([]);

    const addFiles = (toAddFiles_: File[]) => {
        let toAddFiles: File[] = [];

        for (const element of toAddFiles_) {
            if (element.size <= MAX_FILE_SIZE) {
                toAddFiles.push(element);
            } else {
                window.document.dispatchEvent(
                    new CustomEvent("api-toast-result", {
                        detail: {
                            text:
                                "This file could not be uploaded as it exceeds the maximum file size allowed: " +
                                element.name,
                            type: "warning",
                        },
                    })
                );
            }
        }

        if (props.enabled === false) {
            return;
        }

        let newFiles = [];
        let referenceFiles = [...files];

        for (const element of toAddFiles) {
            let newFromComponent = false;
            let newFromPreview = false;

            //** is a new file to the component */
            if (referenceFiles.filter((x) => x.name === element["name"]).length === 0) {
                newFromComponent = true;
            }

            //** is a new file to the component */
            if (preview.filter((x) => x.fileName === element["name"]).length === 0) {
                newFromPreview = true;
            }

            if (newFromComponent && newFromPreview) {
                newFiles.push(element);
                referenceFiles.push(element);
            }
        }
        // emit only newly added files
        props.onDrop(newFiles);

        if (!props.disableInternalListing) {
            setFiles(referenceFiles);
        }
    };

    const removeFile = (index: number) => {
        if (props.enabled === false) {
            return;
        }

        files.splice(index, 1);
        if (!props.disableInternalListing) {
            setFiles([...files]);
        }
    };

    useEffect(() => {
        if (props.preLoadFiles && props.preLoadFiles.length > 0) {
            setPreview(props.preLoadFiles);
        }
        if (props.preLoadFiles && props.preLoadFiles.length === 0) {
            setPreview([]);
        }
    }, [props.preLoadFiles]);

    let displayBoxes = preview;

    return (
        <div>
            <div className="formDropFileWrap">
                <div
                    onClick={() => {
                        document.getElementById(fileId)?.click();
                    }}
                >
                    <DragAndDropContainerInner
                        wrapperClass={props.wrapperClass}
                        onDrop={(f: any) => {
                            addFiles(f);
                        }}
                    >
                        <div style={{ padding: "1em" }}>
                            {props.icon && <div>{props.icon}</div>}
                            {props.title && <div style={{ margin: "0.5em 0", userSelect: "none" }}>{props.title}</div>}
                            {props.lowerElement && <div>{props.lowerElement}</div>}
                        </div>
                    </DragAndDropContainerInner>
                </div>
                {props.enabled !== false && (
                    <div className="drop-input-wrapper">
                        <input
                            multiple={props.multiple}
                            onChange={() => {
                                let input: any = document.getElementById(fileId);
                                if (input != null) {
                                    addFiles(input["files"]);
                                    input["value"] = "";
                                }
                            }}
                            id={fileId}
                            type="file"
                            style={{ position: "absolute", zIndex: "-1" }}
                        />
                    </div>
                )}
            </div>
            {props.allowFileListing && (
                <>
                    {props.topOfListView}
                    <div>
                        {props.customListView && (
                            <div>
                                {props.customListView(displayBoxes).map((x, i) => {
                                    return <div key={i}>{x}</div>;
                                })}
                            </div>
                        )}
                        {!props.customListView &&
                            displayBoxes.map((x, i) => {
                                return (
                                    <div key={i} className="fileUploadedWrap">
                                        <div className="fileUploadedVoice">
                                            <div className="fileUploadedLabel">{x.name}</div>
                                            <button onClick={() => removeFile(i)} className="fileRemoveButton">
                                                x
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </>
            )}
        </div>
    );
};
