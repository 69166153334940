import { QueryClient } from '@tanstack/react-query';
import { getErrorLogsQueryOptions } from '../../../features/error-logs/api/get-error-logs';
import ErrorLogsList from '../../../features/error-logs/components/error-logs-list';

export const errorLogsLoader = (queryClient: QueryClient) => async () => {
    const query = getErrorLogsQueryOptions();

    return await queryClient.ensureQueryData(query);
};

export const ErrorLogsRoute = () => {
    return <ErrorLogsList />;
};
