import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ModalDialog } from "@Eni/docware-fe-master";

import { MAX_TIME_EDITING, MAX_TIME_NOTICE } from "../../../utils/Constants";
import ApiService from "../../../services/api-service";
import { IAPIResponse } from "../../../services/internal/ajax-service";
import AppRoutes from "../../../utils/AppRoutes";
import WindowToast from "../../../utils/window-toast";
import { useLoaderContext } from "../../../stores/loader-context";

interface EditingTimeoutManagerProps {
    wellCode: string | null;
}

const EditingTimeoutManager = (props: EditingTimeoutManagerProps) => {
    const [showKeepEditingModal, setShowKeepEditingModal] = useState<boolean>(false);

    const navigate = useNavigate();
    const loader = useLoaderContext();

    const lockSpudLetter = () => {
        loader.show();
        ApiService.EditLockController.lock(props.wellCode!)
            .then((response: IAPIResponse) => {
                if (response.error !== null) {
                    if (response.raw.status === 403) {
                        navigate(AppRoutes.HOME);
                        throw response.error; // "Spud Letter is locked by another user";
                    } else throw "Error locking Spud Letter";
                }
                setTimeout(() => {
                    setShowKeepEditingModal(true);
                    setTimeout(() => {
                        //TODO this does not stop when user clicks on keep editing; also it won't stop either after the first time it runs
                        navigate(AppRoutes.HOME);
                    }, MAX_TIME_NOTICE);
                }, MAX_TIME_EDITING);
            })
            .catch(WindowToast.error)
            .finally(loader.hide);
    };

    const unlockSpudLetter = () => {
        loader.show();
        ApiService.EditLockController.unlock(props.wellCode!)
            .then((response: IAPIResponse) => {
                if (response.error !== null) {
                    if (response.raw.status === 403) {
                        loader.reset();
                        navigate(AppRoutes.HOME);
                        throw "The letter is currently being edited by another user. Please try again later.";
                    } else throw "Error unlocking Spud Letter";
                }
            })
            .catch(WindowToast.error)
            .finally(loader.hide);
    };

    useEffect(() => {
        lockSpudLetter();

        return () => {
            unlockSpudLetter();
        };
    }, []);

    return (
        <ModalDialog
            modalTitle={"Keep editing Spud Letter?"}
            modalMessage={"Almost 1h has passed"}
            enableModal={showKeepEditingModal}
            modalButtons={[
                {
                    label: "Stop editing",
                    onClick: () => {
                        setShowKeepEditingModal(false);
                        navigate(AppRoutes.HOME);
                    },
                },
                {
                    label: "Keep editing",
                    onClick: () => {
                        lockSpudLetter();
                        setShowKeepEditingModal(false);
                    },
                },
            ]}
            onAbort={() => {
                lockSpudLetter();
                setShowKeepEditingModal(false);
            }}
        />
    );
};

export default EditingTimeoutManager;
