import { QueryClient, useIsMutating } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import SpudLetterUserMenu from '../../../components/user-menu/user-menu';
import { getHydrocarbonPhasesQueryOptions } from '../api/get-hydrocarbon-phases';
import { getInputInfosQueryOptions } from '../api/get-input-infos';
import { getInputOptionsQueryOptions } from '../api/get-input-options';
import Loader from '@/components/loader/loader-component';

export const appLayoutLoader = (queryClient: QueryClient) => async () => {
  const inputOptionsQueryOptions = getInputOptionsQueryOptions();
  const inputInfosQueryOptions = getInputInfosQueryOptions();
  const hydrocarbonPhasesQueryOptions = getHydrocarbonPhasesQueryOptions();

  const promises = [
    queryClient.ensureQueryData(inputOptionsQueryOptions),
    queryClient.ensureQueryData(inputInfosQueryOptions),
    queryClient.ensureQueryData(hydrocarbonPhasesQueryOptions),
  ] as const;

  const [inputOptionsQuery, inputInfosQuery, hydrocarbonPhasesQuery] =
    await Promise.all(promises);

  return {
    inputOptionsQuery,
    inputInfosQuery,
    hydrocarbonPhasesQuery,
  };
};

export const AppLayout = () => {
  const value = useIsMutating();

  return (
    <div style={{ overflow: 'hidden' }}>
      {value > 0 && <Loader display />}
      <SpudLetterUserMenu />
      <Outlet />
    </div>
  );
};
