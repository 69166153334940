import { api } from "@/lib/api-client";

export interface FileInfo {
  id: string;
  fileName: string;
  size: number;
  uploadTime: string;
  url: string;
  inlineUrl: string;
  category: string | null;
  extension: string;
  blobName: string;
}

export const FileUtils = {
  downloadFromUrl: (uri: string, name: string) => {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
};
