import { Navigate, useLocation } from 'react-router-dom';

import { useUserContext } from '../stores/user-context';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useUserContext();
  const location = useLocation();

  if (user === undefined) {
    return (
      <Navigate
        to={`/spud-letter/login?route=${encodeURIComponent(location.pathname)}`}
        replace
      />
    );
  }

  return children;
};
