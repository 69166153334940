import { useDispatch, useSelector } from 'react-redux';

import { ModalDialog } from '@Eni/docware-fe-master';
import { useLoaderContext } from '../../../stores/loader-context';
import { getCompleteField } from '../../../models/MissingFieldsMaps';
import { SpudLetterDto } from '../../../models/SpudLetterDto';
import { useFormContext } from '../stores/form-context';
import { FormActions } from '../../../stores/form/form-actions';
import { GlobalState } from '../../../stores/root-reducer';
import ApiService from '../../../services/api-service';
import { IAPIResponse } from '../../../services/internal/ajax-service';
import WindowToast from '../../../utils/window-toast';

const MissingDataDialog = () => {
    const showMissingDataModal = useSelector<GlobalState, boolean>((state) => state.form.missingDataDialog.show);
    const missingFields = useSelector<GlobalState, (keyof SpudLetterDto)[]>(
        (state) => state.form.missingDataDialog.fields,
    );

    const { formState } = useFormContext();
    const loader = useLoaderContext();

    const dispatch = useDispatch();

    const onAccept = () => {
        loader.show();
        ApiService.SpudLetterController.notifyMissingData(formState.wellCode, missingFields)
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw 'Error sending email';

                WindowToast.success('Email sent successfully');
                dispatch(
                    FormActions.setMissingDataDialog({
                        show: false,
                        fields: [],
                    }),
                );
            })
            .catch(WindowToast.error)
            .finally(loader.hide);
    };

    const onAbort = () => {
        dispatch(
            FormActions.setMissingDataDialog({
                show: false,
                fields: [],
            }),
        );
    };

    return (
        <ModalDialog
            enableModal={showMissingDataModal}
            modalTitle="Some data is missing from the Spud Letter"
            modalInnerComponent={
                <div className="missing-data-modal-content">
                    The following field(s) resulted missing or incomplete:
                    <div className="missing-data-modal-properties">
                        {missingFields.map((x) => getCompleteField(x)).join(', ')}
                    </div>
                    Would you like to notify the respective data owner(s) of this discrepancy? An email will be sent
                    automatically on accept
                </div>
            }
            onAbort={onAbort}
            onAccept={onAccept}
        />
    );
};

export default MissingDataDialog;
