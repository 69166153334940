import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';

import { IDropdownOption, ITag } from '@fluentui/react';
import DropdownClearCaret from '../../../../components/dropdown-clear-caret';
import {
    FluentUIDecorator,
    FluentUIDecoratorTypes,
} from '../../../../components/fluent-ui-decorator/FluentUIDecorator';
import TargetList from '../../../../components/target-list/TargetList';
import {
    CountryReservoirLevel,
    IArisField,
    Prospect,
    ProspectData,
    ReservoirLevel,
    TargetData,
} from '../../../../models/ApiTypes';
import { ITargetItem } from '../../../../models/ITargetItem';
import { SpudLetterDto } from '../../../../models/SpudLetterDto';
import ApiService from '../../../../services/api-service';
import { IAPIResponse } from '../../../../services/internal/ajax-service';
import { FormActions } from '../../../../stores/form/form-actions';
import { useLoaderContext } from '../../../../stores/loader-context';
import { GlobalState } from '../../../../stores/root-reducer';
import { BMT_PHASE_TO_XWARE_PHASE } from '../../../../utils/Constants';
import { FormMode } from '../../../../utils/FormMode';
import { FormSections } from '../../../../utils/FormSections';
import { capitalize, getValueFromOptions } from '../../../../utils/Methods';
import WindowToast from '../../../../utils/window-toast';
import { useHydrocarbonPhases } from '../../../app/api/get-hydrocarbon-phases';
import { filterInputInfo, useInputInfos } from '../../../app/api/get-input-infos';
import { useInputOptions } from '../../../app/api/get-input-options';
import { FormContext } from '../../stores/form-context';
import { useFormModeContext } from '../../stores/form-mode-context';

const targetDataToTargetItem = (target: TargetData): ITargetItem => {
    return {
        id: target.target_cd,
        aliasName: target.trgt_nm,
        targetName: target.trgt_nm,
        targetAge: target.target_age,
        lithology: target.main_lithology_cd,
        md: -1,
        tvdss: -1,
        selected: target.drilled === 'Y',
        userEdited: false,
        well_cd: target.well_cd,
    };
};

const ReservoirInformation = () => {
    const { formState, setFormState } = useContext(FormContext);
    const { mode } = useFormModeContext();

    const dispatch = useDispatch();
    const loader = useLoaderContext();

    const {
        data: { trapTypeOptions, lithologyOptions },
    } = useInputOptions();
    const inputInfosQuery = useInputInfos();
    const hydrocarbonPhasesQuery = useHydrocarbonPhases();

    const ARISnameOptions = useSelector<GlobalState, IArisField[]>((state) => state.form.arisFields);
    const xwareProspectOptions = useSelector<GlobalState, Prospect[]>((state) => state.form.prospects);
    const reservoirFieldOptions = useSelector<GlobalState, CountryReservoirLevel[]>(
        (state) => state.form.reservoirOptions,
    );

    // const arisFieldsQuery = useArisFields({ countryCode: formState.country?.key?.toString() });

    // useEffect(() => {
    //     if (formState.ownership?.key !== "E") {
    //         checkMissingField(arisFieldsQuery.data, "arisField");
    //     }
    // }, [arisFieldsQuery.data]);
    // const prospectQuery = useProspects({ countryCode: formState.country?.key?.toString() });
    // const reservoirFieldsQuery = useReservoirFields({
    //     arisFieldId: formState.arisField?.key?.toString(),
    //     countryCode: formState.country?.key?.toString(),
    // });
    // const reservoirLevelsQuery = useReservoirLevels({ reservoirFieldId: formState.reservoirField?.key?.toString() });
    // const reservoirLevelOptions: IDropdownOption[] = reservoirLevelsQuery.data.map((x) => ({
    //     key: x.reserv_level_id,
    //     text: x.reser_level_name,
    // }));
    const [reservoirLevelOptions, setReservoirLevelOptions] = useState<IDropdownOption[]>([]);

    const phase = hydrocarbonPhasesQuery.data.find((x) => x.hydrocarbon_phase_cd === formState.hydrocarbonPhase);
    const mainFluid = phase?.main_hydrocarbon_phase_desc;
    const fluidDetail = phase?.hydrocarbon_phase_detailed;

    let lithologies = formState.estimatedTargets
        .filter((x) => x.selected)
        .map((x) => getValueFromOptions(lithologyOptions, x?.lithology))
        .filter((x) => !!x);

    const lithology = [...new Set(lithologies)].join(', ');

    if (formState.lithology !== lithology)
        setFormState((prev) => ({
            ...prev,
            lithology,
        }));

    useEffect(() => {
        if (formState.reservoirField?.key) getReservoirLevel(formState.reservoirField?.key?.toString());
    }, []);

    useEffect(() => {
        if (ARISnameOptions.length === 1 && !formState.arisField?.key) {
        }
    }, [ARISnameOptions]);

    const isReviewedEdit = mode === FormMode.ReviewedEdit;

    const notifications = formState.missingDataNotifications;

    const checkMissingField = (payload: string | number | any[], propertyName: keyof SpudLetterDto) => {
        if (mode !== FormMode.Edit) return;
        if (typeof payload === 'object' && payload.length !== 0) return;
        if (!!payload) return;
        let notification = notifications.filter((x) => x.field === propertyName);
        if (_.isEmpty(notification) || !_.first(notification).notificationSent) {
            dispatch(
                FormActions.setMissingDataDialog({
                    show: true,
                    fields: [propertyName],
                }),
            );
        }
    };

    const getReservoirLevel = (resFieldId: string) => {
        loader.show();
        ApiService.InboundController.getReservoirLevel(
            resFieldId ?? formState.reservoirField?.key?.toString(),
            (response: IAPIResponse) => {
                loader.hide();
                if (response.error === null) {
                    let reservoirLevels = response.payload as ReservoirLevel[];

                    if (reservoirLevels.length === 0) {
                        let notification = formState.missingDataNotifications.filter(
                            (x) => x.field === 'reservoirLevel',
                        );
                        if (_.isEmpty(notification) || !_.first(notification).notificationSent) {
                            dispatch(
                                FormActions.setMissingDataDialog({
                                    show: true,
                                    fields: ['reservoirLevel'],
                                }),
                            );
                        }
                    }

                    setReservoirLevelOptions(
                        reservoirLevels.map((x) => ({
                            key: x.reserv_level_id,
                            text: x.reser_level_name,
                        })),
                    );
                } else {
                    WindowToast.error('Error retrieving reservoir levels');
                }
            },
        );
    };

    const onChangeReservoirField = (option: IDropdownOption) => {
        setFormState((prev) => ({
            ...prev,
            reservoirField: option,
            reservoirLevel: [],
        }));
        getReservoirLevel(option.key.toString());
    };

    /**
     * To be called only on user input
     * @param arisField
     */
    const onChangeAris = (arisField: ITag) => {
        if (arisField === null) {
            setFormState((prev) => ({
                ...prev,
                arisField: null,
                prospect: null,
                estimatedTargets: [],
                hiip: '',
                pos: '',
                trapType: null,
                formationAge: '',
                lithology: '',
                hydrocarbonPhase: '',
            }));
            return;
        }

        setFormState((prev) => ({ ...prev, arisField: arisField }));

        if (formState.ownership && formState.ownership.key === 'E') {
            loader.show();
            //Get data SPUD_LETTER_DATA_V based on aris
            ApiService.InboundController.getProspectData(
                formState.wellCode,
                arisField.key.toString(),
                undefined,
                (response: IAPIResponse) => {
                    if (response.raw.status === 204 || response.payload == null) {
                        // WindowToast.info('No prospect was associated with this aris field, please choose one');
                        dispatch(
                            FormActions.setMissingDataDialog({
                                show: true,
                                fields: ['estimatedTargets'],
                            }),
                        );
                        return;
                    }
                    let payload = response.payload as ProspectData;

                    // checkMissingField(payload.prspct_id, 'prospect');
                    checkMissingField(payload.targets, 'estimatedTargets');
                    if (payload.targets.length > 0) {
                        checkMissingField(
                            payload.targets.filter((x) => x.target_age_cd == null),
                            'formationAge',
                        );
                        checkMissingField(
                            payload.targets.filter((x) => x.main_lithology_cd == null),
                            'lithology',
                        );
                    }
                    checkMissingField(payload.hiip_prognosis, 'hiip');
                    checkMissingField(payload.pos_prognosis, 'pos');
                    checkMissingField(payload.trap_type_prspct_cd, 'trapType');
                    checkMissingField(payload.hc_phase, 'hydrocarbonPhase');

                    let hc_phase = hydrocarbonPhasesQuery.data.find(
                        (x) => x.main_hydrocarbon_phase_desc === BMT_PHASE_TO_XWARE_PHASE[payload.hc_phase],
                    );

                    setFormState((prev) => ({
                        ...prev,
                        arisField: arisField,
                        prospect: { name: payload.prspct_name, key: payload.prspct_id },
                        estimatedTargets: payload.targets.map((x) => targetDataToTargetItem(x)),
                        hiip: payload.hiip_prognosis.toFixed(0),
                        pos: payload.pos_prognosis.toString(),
                        trapType: {
                            key: payload.trap_type_prspct_cd,
                            text: payload.trap_type_prspct_desc,
                        },
                        hydrocarbonPhase: hc_phase.hydrocarbon_phase_cd,
                    }));
                },
            ).finally(loader.hide);
        }

        loader.show();
        ApiService.InboundController.getCountryReservoirLevels(arisField.key.toString())
            .then((response) => {
                if (response.error !== null || response.payload == null) {
                    return;
                }
                let payload = response.payload as CountryReservoirLevel[];
                dispatch(FormActions.setReservoirOptions(payload));
                let uniqueReservoirFields = payload
                    .map((x) => ({
                        key: x.reserv_field_id,
                        text: x.reservoir_field_name,
                    }))
                    .filter((x, index, self) => index === self.findIndex((t) => t.key === x.key));
                if (uniqueReservoirFields.length === 1) {
                    const item = _.first(uniqueReservoirFields);
                    setFormState((prev) => ({
                        ...prev,
                        reservoirField: item,
                        reservoirLevel: [],
                    }));
                } else {
                    setFormState((prev) => ({
                        ...prev,
                        reservoirField: null,
                        reservoirLevel: [],
                    }));
                }
            })
            .finally(loader.hide);
    };

    /**
     * To be called only on user input
     * @param prospect
     */
    const onChangeProspect = (prospect: ITag | null) => {
        if (prospect === null) {
            setFormState((prev) => ({
                ...prev,
                prospect: null,
                estimatedTargets: [],
                hiip: '',
                pos: '',
                trapType: null,
                formationAge: '',
                lithology: '',
                hydrocarbonPhase: '',
            }));
            return;
        }

        setFormState((prev) => ({ ...prev, prospect: prospect }));

        if (formState.ownership.key !== 'E') {
            return;
        }

        loader.show();
        //Get data SPUD_LETTER_DATA_V based on prospect
        ApiService.InboundController.getProspectData(formState.wellCode, undefined, prospect.key.toString())
            .then((response: IAPIResponse) => {
                if (response.raw.status === 204 || response.payload === null) {
                    dispatch(
                        FormActions.setMissingDataDialog({
                            show: true,
                            fields: ['estimatedTargets'],
                        }),
                    );
                    // WindowToast.info('No targets were associated with the prospect');
                    return;
                }
                let payload = response.payload as ProspectData;

                // checkMissingField(payload.prspct_id, 'prospect');
                checkMissingField(payload.targets, 'estimatedTargets');
                if (payload.targets.length > 0) {
                    checkMissingField(
                        payload.targets.filter((x) => x.target_age_cd == null),
                        'formationAge',
                    );
                    checkMissingField(
                        payload.targets.filter((x) => x.main_lithology_cd == null),
                        'lithology',
                    );
                }
                checkMissingField(payload.hiip_prognosis, 'hiip');
                checkMissingField(payload.pos_prognosis, 'pos');
                checkMissingField(payload.trap_type_prspct_cd, 'trapType');
                checkMissingField(payload.hc_phase, 'hydrocarbonPhase');

                let hc_phase = hydrocarbonPhasesQuery.data.find(
                    (x) => x.main_hydrocarbon_phase_desc === BMT_PHASE_TO_XWARE_PHASE[payload.hc_phase],
                );

                //assign targets and the rest
                setFormState((prev) => ({
                    ...prev,
                    prospect: prospect,
                    estimatedTargets: payload.targets.map((x) => targetDataToTargetItem(x)),
                    hiip: payload.hiip_prognosis.toFixed(0),
                    pos: payload.pos_prognosis.toString(),
                    trapType: {
                        key: payload.trap_type_prspct_cd,
                        text: payload.trap_type_prspct_desc,
                    },
                    hydrocarbonPhase: hc_phase.hydrocarbon_phase_cd,
                }));
            })
            .catch(WindowToast.error)
            .finally(loader.hide);
    };

    const onChangeHydrocarbonPhase = (mainFluid?: string, fluidDetail?: string) => {
        if (!!mainFluid) {
            setFormState((prev) => ({
                ...prev,
                hydrocarbonPhase: hydrocarbonPhasesQuery.data.find((x) => x.main_hydrocarbon_phase_desc === mainFluid)
                    .hydrocarbon_phase_cd,
            }));
        } else if (fluidDetail) {
            setFormState((prev) => ({
                ...prev,
                hydrocarbonPhase: hydrocarbonPhasesQuery.data.find((x) => x.hydrocarbon_phase_detailed === fluidDetail)
                    .hydrocarbon_phase_cd,
            }));
        }
    };

    useEffect(() => {
        //TODO replace with useFormationAge
        ApiService.InputOptionsController.getFormationAge(
            formState.estimatedTargets.filter((x) => x.selected).map((x) => x.targetAge),
        ).then((res) => {
            let formationAge = res.payload as string;
            setFormState((prev) => ({
                ...prev,
                formationAge,
            }));
        });
        // .catch(WindowToast.error);
    }, [formState.estimatedTargets]);

    let resLevelPlaceholder = 'Not available';
    if (formState.reservoirField) {
        resLevelPlaceholder = reservoirLevelOptions.length > 0 ? 'Select' : 'No level available for this Field';
    }

    const pickerRef = React.useRef(null);

    return (
        <>
            <div className="field-row">
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Aris Field name"
                        info={filterInputInfo(inputInfosQuery.data, 'reservoir-info', 'aris-field')}
                        required={false}
                        fluentComponent={FluentUIDecoratorTypes.TagPicker({
                            ref: pickerRef,
                            disabled: isReviewedEdit,
                            pickerSuggestionsProps: {
                                suggestionsHeaderText: 'Suggested aris fields',
                                noResultsFoundText: 'No aris fields found',
                            },
                            inputProps: {
                                onBlur: (event) => {
                                    if (!event.relatedTarget) {
                                        pickerRef.current.input.current._updateValue('');
                                    }
                                },
                            },
                            onResolveSuggestions: (filterText: string, tagList: ITag[]) => {
                                if (!filterText) return [];
                                return ARISnameOptions.map((x) => ({
                                    key: x.aris_field_id,
                                    name: x.field_desc,
                                })).filter((tag) => {
                                    return tag.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
                                });
                            },
                            onItemSelected: (selectItem: ITag) => {
                                onChangeAris(selectItem);
                                return selectItem;
                            },
                            onChange: (items: ITag[]) => {
                                if (items.length === 0) {
                                    onChangeAris(null);
                                }
                            },
                            itemLimit: 1,
                            defaultSelectedItems: formState.arisField ? [formState.arisField] : [],
                            // options: ARISnameOptions.map(
                            //     (x) =>
                            //         ({
                            //             key: x.aris_field_id,
                            //             text: x.field_desc,
                            //         } as IDropdownOption)
                            // ),
                            // selectedKey: formState.arisField?.key,
                            // onChange: (event, option) => onChangeAris(option),
                            // onRenderCaretDown: () => (
                            //     <DropdownClearCaret
                            //         condition={formState.arisField !== null}
                            //         onClear={() => setFormState((prev) => ({ ...prev, arisField: null }))}
                            //     />
                            // ),
                        })}
                    />
                </div>
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Prospect"
                        info={filterInputInfo(inputInfosQuery.data, 'reservoir-info', 'prospect')}
                        required={false}
                        fluentComponent={FluentUIDecoratorTypes.TagPicker({
                            disabled: isReviewedEdit,
                            pickerSuggestionsProps: {
                                suggestionsHeaderText: 'Suggested prospects',
                                noResultsFoundText: 'No prospects found',
                            },
                            onResolveSuggestions: (filterText: string, tagList: ITag[]) => {
                                if (!filterText) return [];
                                return xwareProspectOptions //not ProspectData
                                    .map((x) => ({ key: x.prsp_id, name: x.prsp_name }))
                                    .filter((tag) => {
                                        return tag.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
                                    });
                            },
                            onItemSelected: (selectItem: ITag) => {
                                onChangeProspect(selectItem);
                                return selectItem;
                            },
                            onChange: (items: ITag[]) => {
                                if (items.length === 0) {
                                    onChangeProspect(null);
                                }
                            },
                            itemLimit: 1,
                            defaultSelectedItems: formState.prospect ? [formState.prospect] : [],
                        })}
                    />
                </div>
            </div>

            <hr style={{ marginTop: '2em', marginBottom: '2rem' }} />

            {formState.ownership?.key === 'E' && (
                <>
                    <span className="form-section-title">{FormSections.ESTIMATED_TARGET.title}</span>

                    <div className="form-summary-form-container">
                        <TargetList estimatedTargets={formState.estimatedTargets} setState={setFormState} mode={mode} />

                        <hr style={{ marginTop: '2em', marginBottom: '2rem' }} />

                        <span className="form-section-title">Exploration Only</span>

                        <div className="field-row">
                            <div className="field-33 ">
                                <FluentUIDecorator
                                    label="HIIP [MBOE]"
                                    info={filterInputInfo(inputInfosQuery.data, 'reservoir-info', 'hiip')}
                                    required
                                    errorMessage={
                                        (window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined
                                    }
                                    fluentComponent={FluentUIDecoratorTypes.TextField({
                                        disabled: isReviewedEdit,
                                        type: 'number',
                                        placeholder: 'e.g.: 642',
                                        value: formState.hiip,
                                        onChange: (event, text) => setFormState((prev) => ({ ...prev, hiip: text })),
                                    })}
                                />
                            </div>
                            <div className="field-33 ">
                                <FluentUIDecorator
                                    label="POS [%]"
                                    info={filterInputInfo(inputInfosQuery.data, 'reservoir-info', 'pos')}
                                    required
                                    errorMessage={
                                        (window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined
                                    }
                                    fluentComponent={FluentUIDecoratorTypes.TextField({
                                        disabled: isReviewedEdit,
                                        type: 'number',
                                        placeholder: 'e.g.: 55',
                                        value: formState.pos,
                                        onChange: (event, text) => setFormState((prev) => ({ ...prev, pos: text })),
                                    })}
                                />
                            </div>
                            <div className="field-33 ">
                                <FluentUIDecorator
                                    label="Trap Type"
                                    info={filterInputInfo(inputInfosQuery.data, 'reservoir-info', 'trap-type')}
                                    fluentComponent={FluentUIDecoratorTypes.Dropdown({
                                        disabled: isReviewedEdit,
                                        placeholder: 'Select',
                                        options: trapTypeOptions,
                                        selectedKey: formState.trapType?.key,
                                        onChange: (event, option) =>
                                            setFormState((prev) => ({ ...prev, trapType: option })),
                                        onRenderCaretDown: () => (
                                            <DropdownClearCaret
                                                condition={formState.trapType !== null}
                                                onClear={() => setFormState((prev) => ({ ...prev, trapType: null }))}
                                            />
                                        ),
                                    })}
                                />
                            </div>
                        </div>

                        <div className="field-row">
                            <div className="field-50 ">
                                <FluentUIDecorator
                                    label="Formation Age"
                                    info={filterInputInfo(inputInfosQuery.data, 'reservoir-info', 'formation-age')}
                                    fluentComponent={FluentUIDecoratorTypes.TextField({
                                        placeholder: 'Not available',
                                        value: formState.formationAge || '',
                                        disabled: true,
                                    })}
                                />
                            </div>
                            <div className="field-50 ">
                                <FluentUIDecorator
                                    label="Lithology"
                                    info={filterInputInfo(inputInfosQuery.data, 'reservoir-info', 'lithology')}
                                    fluentComponent={FluentUIDecoratorTypes.TextField({
                                        placeholder: 'Not available',
                                        value: formState.lithology,
                                        disabled: true,
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {true && (
                <div className="field-row">
                    <div className="field-50 ">
                        <FluentUIDecorator
                            label="Reservoir Field"
                            info={filterInputInfo(inputInfosQuery.data, 'reservoir-info', 'reservoir-field')}
                            required={['C', 'O'].includes(formState.ownership?.key)}
                            fluentComponent={FluentUIDecoratorTypes.Dropdown({
                                disabled: isReviewedEdit,
                                placeholder: 'Select',
                                options: reservoirFieldOptions
                                    .map((x) => ({
                                        key: x.reserv_field_id,
                                        text: x.reservoir_field_name,
                                    }))
                                    .filter((x, index, self) => index === self.findIndex((t) => t.key === x.key)),
                                selectedKey: formState.reservoirField?.key,
                                onChange: (event, option) => onChangeReservoirField(option),
                                onRenderCaretDown: () => (
                                    <DropdownClearCaret
                                        condition={formState.reservoirField !== null}
                                        onClear={() =>
                                            setFormState((prev) => ({
                                                ...prev,
                                                reservoirField: null,
                                                reservoirLevel: [],
                                            }))
                                        }
                                    />
                                ),
                            })}
                        />
                    </div>
                    <div className="field-50 ">
                        <FluentUIDecorator
                            label="Reservoir Level(s)"
                            info={filterInputInfo(inputInfosQuery.data, 'reservoir-info', 'reservoir-level')}
                            required={['C', 'O'].includes(formState.ownership?.key)}
                            fluentComponent={FluentUIDecoratorTypes.Dropdown({
                                disabled: isReviewedEdit,
                                placeholder: resLevelPlaceholder,
                                options: reservoirFieldOptions
                                    .filter((x) => x.reserv_field_id === formState.reservoirField?.key)
                                    .map((x) => ({
                                        key: x.reserv_level_id,
                                        text: x.reserv_level_name,
                                    })),
                                selectedKeys: formState.reservoirLevel.map((x) => x.key.toString()),
                                multiSelect: true,
                                onChange: (event, option) => {
                                    if (option) {
                                        setFormState((prev) => ({
                                            ...prev,
                                            reservoirLevel: option.selected
                                                ? [...prev.reservoirLevel, option]
                                                : prev.reservoirLevel.filter((x) => x.key !== option.key),
                                        }));
                                    }
                                },
                            })}
                        />
                    </div>
                </div>
            )}

            <div className="field-row">
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Estimated main fluid"
                        info={filterInputInfo(inputInfosQuery.data, 'reservoir-info', 'main-fluid')}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            disabled: isReviewedEdit,
                            placeholder: 'Select',
                            options: _.uniq(
                                hydrocarbonPhasesQuery.data,
                                false,
                                (p) => p.main_hydrocarbon_phase_desc,
                            ).map((x) => ({
                                key: x.main_hydrocarbon_phase_desc,
                                text: capitalize(x.main_hydrocarbon_phase_desc),
                            })),
                            selectedKey: mainFluid,
                            onChange: (event, option) => onChangeHydrocarbonPhase(option.key.toString(), undefined),
                        })}
                    />
                </div>
                <div className="field-50 ">
                    <FluentUIDecorator
                        label="Fluid details"
                        info={filterInputInfo(inputInfosQuery.data, 'reservoir-info', 'fluid-details')}
                        fluentComponent={FluentUIDecoratorTypes.Dropdown({
                            disabled: isReviewedEdit || !mainFluid,
                            placeholder: 'Select',
                            options: hydrocarbonPhasesQuery.data
                                .filter((x) => x.main_hydrocarbon_phase_desc === mainFluid)
                                .map((x) => ({
                                    key: x.hydrocarbon_phase_detailed,
                                    text: capitalize(x.hydrocarbon_phase_detailed),
                                })),
                            selectedKey: fluidDetail,
                            onChange: (event, option) => onChangeHydrocarbonPhase(undefined, option.key.toString()),
                        })}
                    />
                </div>
            </div>
        </>
    );
};

export default ReservoirInformation;
