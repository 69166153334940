import { FILE_ICONS, LabelButton } from '@Eni/docware-fe-master';
import { MarqueeSelection, mergeStyleSets, SearchBox, TooltipHost } from '@fluentui/react';
import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { useCallback, useEffect, useState } from 'react';
import './GenericList.scss';

export type ColumnType<T> = {
    name: string;
    size: 'small' | 'little' | '7-digit' | 'medium' | 'bigger' | 'large' | 'wide';
    dataType: 'string' | 'date' | null;
    fieldName: keyof T;
};

const getFileIcon = (fileName: string) => {
    if (!fileName) {
        return { url: null, docType: 'Unknown' };
    }

    let nameSpl = fileName.split('.');
    let format = nameSpl[nameSpl.length - 1].toLowerCase();

    for (let i = 0; i < FILE_ICONS.length; i++) {
        let icon = FILE_ICONS[i];
        if (icon.name === format) {
            return {
                url: `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${icon.name}.svg`,
                docType: icon.name,
            };
        }
        if (icon.validFor) {
            let matched = icon.validFor.indexOf(format);
            if (matched !== -1) {
                return {
                    url: `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${icon.name}.svg`,
                    docType: icon.validFor[matched],
                };
            }
        }
    }
    return null;
};

// Supported classes
const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});

const extractItemValueOnPath = (item: any, path: string) => {
    try {
        let dotPath: string[] = path.split('.');
        for (let i = 0; i < dotPath.length; i++) {
            item = item[dotPath[i]];
        }
        return item;
    } catch (e) {
        return null;
    }
};

export interface IGenericListProps {
    onSelectionChanged?: (items: any[]) => void;
    onItemInvoked?: (item: any) => void;
    allowFilter?: boolean;
    searchText?: string;
    emitSearchedText?: (text: string) => void;
    columns: any[];
    items: any[];
    selectionMode?: SelectionMode;
    initialSelection?: (item: any) => boolean;
    iconFromField?: string;
    extraHeaderItems?: JSX.Element[];
    itemsPerPage?: number;
    page?: number;
}

const GenericList = (props: IGenericListProps) => {
    const [filterValue, setFilterValue] = useState<string>('');
    const [activeFilterValue, setActiveFilterValue] = useState<string>('');
    const [holdOn, setHoldOn] = useState<boolean>(false);

    const [items, setItems] = useState<any[]>([]);
    const [columns, setColumns] = useState<IColumn[]>([]);
    const [selection] = useState<Selection>(
        new Selection({
            onSelectionChanged: () => {
                if (props.onSelectionChanged) {
                    props.onSelectionChanged(selection.getSelection());
                }
            },
        }),
    );

    /** callback when row is clicked */
    const onItemInvoked = (item: any): void => {
        if (props.onItemInvoked) {
            props.onItemInvoked(item);
        }
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        setItems(newItems);
        setColumns(newColumns);
    };

    const generateColumn = (
        index: number,
        name: string,
        fieldName: string,
        dataType: string,
        size: string,
        iconName: string,
        onRender: any,
        isMultiline?: boolean,
    ): IColumn => {
        let baseColumn: IColumn = {
            key: 'column' + index.toString(),
            name: name,
            fieldName: fieldName,
            minWidth: 0,
            maxWidth: 0,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: dataType,
            isPadded: true,
            isMultiline: isMultiline,
            onRender: (item: any) => {
                let it = extractItemValueOnPath(item, fieldName);
                if (dataType === 'date') {
                    let str = new Date(it).toLocaleDateString();
                    return <span title={str}>{str}</span>;
                } else {
                    return <span title={it}>{it}</span>;
                }
            },
        };

        /* is an icon only header */
        if (iconName != null) {
            baseColumn['iconName'] = iconName;
            baseColumn['isResizable'] = false;
            baseColumn['isIconOnly'] = true;
            baseColumn['onRender'] = (item: any) => (
                <TooltipHost content={`${item.fileType} file`}>
                    <img src={item.iconName} className={classNames.fileIconImg} alt={`${item.fileType} file icon`} />
                </TooltipHost>
            );
        }

        /* custom on render function */
        if (onRender != null && onRender !== undefined) {
            baseColumn['onRender'] = onRender;
        }

        if (size === 'small') {
            baseColumn['minWidth'] = 16;
            baseColumn['maxWidth'] = 16;
        }

        if (size === 'little') {
            baseColumn['minWidth'] = 20;
            baseColumn['maxWidth'] = 40;
        }

        if (size === '7-digit') {
            baseColumn['minWidth'] = 40;
            baseColumn['maxWidth'] = 100;
        }

        if (size === 'medium') {
            baseColumn['minWidth'] = 100;
            baseColumn['maxWidth'] = 120;
        }

        if (size === 'bigger') {
            baseColumn['minWidth'] = 160;
            baseColumn['maxWidth'] = 190;
        }

        if (size === 'large') {
            baseColumn['minWidth'] = 210;
            baseColumn['maxWidth'] = 350;
        }

        if (size === 'wide') {
            baseColumn['minWidth'] = 450;
            baseColumn['maxWidth'] = 600;
        }

        return baseColumn;
    };

    const copyAndSort = (items: any[], columnKey: string, isSortedDescending?: boolean): any[] => {
        return items
            .slice(0)
            .sort((a: any, b: any) =>
                (isSortedDescending ? a[columnKey] < b[columnKey] : a[columnKey] > b[columnKey]) ? 1 : -1,
            );
    };

    const init = useCallback(() => {
        let columns = props.columns ? props.columns : [];
        let items = props.items ? props.items : [];

        const processedColumns: IColumn[] = columns.map((column, index) => {
            return generateColumn(
                index,
                column['name'],
                column['fieldName'],
                column['dataType'],
                column['size'],
                column['iconName'],
                column['onRender'],
                column['isMultiline'],
            );
        });

        return { items: items, columns: processedColumns };
    }, [props.columns, props.items]);

    useEffect(() => {
        let data = init();
        setItems(data.items.slice());
        setColumns(data.columns);
    }, [init]);

    for (let i = 0; i < columns.length; i++) {
        columns[i]['onColumnClick'] = onColumnClick;
    }

    if (props.iconFromField) {
        for (let i = 0; i < items.length; i++) {
            let item: any = items[i];
            let fileName = extractItemValueOnPath(item, props.iconFromField);
            let iconItem = getFileIcon(fileName);
            if (iconItem) {
                item['iconName'] = iconItem.url;
                item['fileType'] = iconItem.docType;
            }
        }
    }

    const filterItems = (rows: any, text: string) => {
        if (text === '' || props.emitSearchedText) {
            if (props.page === undefined || props.itemsPerPage === undefined) {
                return rows;
            }
            return rows.slice(props.page * props.itemsPerPage, (props.page + 1) * props.itemsPerPage);
        }

        let acceptItems: any[] = [];
        for (let i = 0; i < rows.length; i++) {
            let obj = rows[i];
            if (JSON.stringify(obj).toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) !== -1) {
                acceptItems.push(obj);
            }
        }

        if (props.page === undefined || props.itemsPerPage === undefined) return acceptItems;
        return acceptItems.slice(props.page * props.itemsPerPage, (props.page + 1) * props.itemsPerPage);
    };

    useEffect(() => {
        const setSelection = () => {
            if (props.initialSelection && props.items.length > 0) {
                for (let i = 0; i < props.items.length; i++) {
                    if (props.initialSelection(props.items[i])) {
                        selection.setIndexSelected(i, true, true);
                    }
                }
            }
        };
        setTimeout(() => {
            try {
                setSelection();
            } catch (e) {}
        }, 100);
    }, [props.items]);

    const filteredItems = filterItems(items, activeFilterValue);

    const doSearch = () => {
        setHoldOn(true);
        setActiveFilterValue(filterValue);

        if (props.emitSearchedText) {
            props.emitSearchedText(filterValue);
        }

        setTimeout(() => {
            try {
                setHoldOn(false);
            } catch (e) {}
        }, 500);
    };

    return (
        <div>
            {props.allowFilter && (
                <div className="generic-list-seach-wrap">
                    <div className="generic-list-seach-wrap-row">
                        {props.extraHeaderItems ? props.extraHeaderItems : []}
                    </div>
                    <div className="generic-list-seach-wrap-row">
                        <SearchBox
                            placeholder={props.searchText ?? 'Search...'}
                            value={filterValue}
                            onChange={(e: any) => {
                                setFilterValue(e.target.value);
                            }}
                            onKeyUp={(e: any) => {
                                if (e.keyCode === 13) {
                                    doSearch();
                                }
                            }}
                            onClear={() => {
                                setFilterValue('');
                                setActiveFilterValue('');
                                if (props.emitSearchedText) {
                                    props.emitSearchedText('');
                                }
                            }}
                        />
                        <div style={{ width: '0.5em' }}></div>
                        <LabelButton
                            disabled={filterValue === ''}
                            whiteOutlined
                            text={'Search'}
                            onClick={() => {
                                doSearch();
                            }}
                        />
                    </div>
                </div>
            )}
            <MarqueeSelection selection={selection}>
                <DetailsList
                    items={holdOn ? [] : filteredItems}
                    columns={columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={props.selectionMode ? props.selectionMode : SelectionMode.none}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                    onItemInvoked={onItemInvoked}
                    selection={selection}
                    isSelectedOnFocus={false}
                />
            </MarqueeSelection>
        </div>
    );
};

export default GenericList;
