import './review.scss';

import { Icon, Persona } from '@fluentui/react';
import ReviewAccordion from '../../../../components/review-accordion/ReviewAccordion';
import StatusBubble from '../../../../components/status-bubble/StatusBubble';
import { WorkflowRole, WorkflowSigner } from '../../../../models/SpudLetterDto';
import { SpudLetterStatus } from '../../../../models/SpudLetterStatus';
import { UserRole } from '../../../../models/UserRole';
import { useUserContext } from '../../../../stores/user-context';
import { DEFAULT_LOCALE } from '../../../../utils/Constants';
import { FormSections } from '../../../../utils/FormSections';
import { capitalize } from '../../../../utils/Methods';
import { useHydrocarbonPhases } from '../../../app/api/get-hydrocarbon-phases';
import { useFormContext } from '../../stores/form-context';
import { useFormModeContext } from '../../stores/form-mode-context';
import { ReviewItem, ReviewItemRow } from './review-item';

export const getSignerOfRole = (signers: WorkflowSigner[], role: WorkflowRole) => {
    return signers.find((x) => x.workflowRole === role);
};

const PersonaFromSigner = ({ signer }: { signer?: WorkflowSigner }) => {
    return !!signer ? <Persona key={signer.id} text={signer.name} secondaryText={signer.email} /> : <>--</>;
};

const Review = ({ onEditFromReview }: { onEditFromReview: (step: number) => void }) => {
    const { formState } = useFormContext();
    const { setMode } = useFormModeContext();

    const { user: currentUser } = useUserContext();
    const hydrocarbonPhasesQuery = useHydrocarbonPhases();

    const phase = hydrocarbonPhasesQuery.data.find((x) => x.hydrocarbon_phase_cd === formState.hydrocarbonPhase);
    const mainFluid = phase?.main_hydrocarbon_phase_desc;
    const fluidDetail = phase?.hydrocarbon_phase_detailed;

    const isEditable =
        (formState.status === SpudLetterStatus.Draft || formState.status === SpudLetterStatus.ReviewedDraft) &&
        currentUser?.roles?.some((x) => x === UserRole.Writer) &&
        !formState.locked;

    // const navigate = useNavigateForm();

    // const onEditClicked = (index: number) => {
    //     setMode(FormMode.Edit);
    //     navigate(formRoutes[index]);
    // };

    return (
        <div style={{ marginTop: '1em' }}>
            <ReviewAccordion
                title={`01 ${FormSections.GENERAL_INFO.title}`}
                isEditable={isEditable}
                onEditClicked={() => onEditFromReview(0)}
            >
                <ReviewItemRow
                    items={[
                        { title: 'Place', value: formState.place },
                        { title: 'Date', value: formState.date?.toLocaleDateString(DEFAULT_LOCALE) },
                        { title: 'From', value: formState.from },
                        {
                            title: 'Status',
                            value: <StatusBubble documentStatus={formState.status} isOldVersion={false} />,
                        },
                    ]}
                />
                <ReviewItemRow
                    items={[
                        { title: 'Well Code', value: formState.wellCode },
                        { title: 'Well name', value: formState.wellName },
                        { title: 'Reference System', value: formState.referenceSystem?.text },
                        { title: 'Ownership', value: formState.ownership?.text },
                    ]}
                />
                <hr style={{ marginTop: '1em', marginBottom: '1em' }} />
                <ReviewItemRow
                    items={[
                        { title: 'Country', value: formState.country?.name },
                        { title: 'Block Name / License', value: formState.blockName },
                        {
                            title: 'Operator (Eni Shares)',
                            value: `${formState.operator} (PI ${formState.participatingInterest}%; WI ${formState.workingInterest}%)`,
                        },
                    ]}
                />
                <ReviewItemRow
                    items={[{ title: 'Partnership (participating interest)', value: formState.partnerships }]}
                />
                <ReviewItemRow
                    items={[
                        { title: 'Onshore/Offshore', value: formState.onshoreOffshore?.text },
                        {
                            title: 'Eni operated',
                            value: formState.eniOperated ? <Icon iconName="CheckMark" /> : <Icon iconName="Cancel" />,
                        },
                    ]}
                />
            </ReviewAccordion>

            <ReviewAccordion
                title={`02 ${FormSections.RESERVOIR_INFO.title}`}
                isEditable={isEditable}
                onEditClicked={() => onEditFromReview(1)}
            >
                <div className="review-row">
                    <ReviewItem title="ARIS Field Name" value={formState.arisField?.name} />
                    <ReviewItem title="Prospect" value={formState.prospect?.name} />
                </div>
                {formState.ownership?.key === 'E' && (
                    <>
                        <div className="review-row">
                            <ReviewItem
                                title="Estimated Target"
                                value={formState.estimatedTargets
                                    .filter((x) => x.selected)
                                    .map((x) => x.aliasName)
                                    .join(', ')}
                                fullWidth
                            />
                        </div>
                        <div className="review-row">
                            <ReviewItem title="HIIP [MBOE]" value={formState.hiip} />
                            <ReviewItem title="POS [%]" value={formState.pos} />
                            <ReviewItem title="Trap Type" value={formState.trapType?.text} />
                        </div>
                        <div className="review-row">
                            <ReviewItem title="Formation Age" value={formState.formationAge} />
                            <ReviewItem title="Lithology" value={formState.lithology} />
                        </div>
                    </>
                )}
                <div className="review-row">
                    <ReviewItem title="Reservoir Field" value={formState.reservoirField?.text} />
                    <ReviewItem
                        title="Reservoir Level(s)"
                        value={formState.reservoirLevel?.map((x) => x.text)?.join(', ')}
                    />
                    <ReviewItem title="Expected main fluid" value={capitalize(mainFluid)} />
                    <ReviewItem title="Fluid details" value={capitalize(fluidDetail)} />
                </div>
            </ReviewAccordion>

            <ReviewAccordion
                title={`03 ${FormSections.WELL_INFO.title}`}
                isEditable={isEditable}
                onEditClicked={() => onEditFromReview(2)}
            >
                <div className="review-row">
                    <ReviewItem title="Spud date" value={formState.spudDate?.toLocaleDateString(DEFAULT_LOCALE)} />
                    <ReviewItem title="Rig" value={formState.rig} />
                    <ReviewItem title="Rig contractor" value={formState.rigContractor} />
                </div>
                <div className="review-row">
                    <ReviewItem title="Production Type" value={formState.productionType?.text} />
                    <ReviewItem title="Path" value={formState.path?.text} />
                    <ReviewItem title="Water depth/Ground Level(m)" value={formState.waterDepth} />
                    <ReviewItem title="Rotary Table" value={formState.rotaryTable} />
                </div>
                <ReviewItemRow
                    items={[
                        {
                            title: 'Latitude',
                            value: `${formState.latitude} ${formState.latDirection}`,
                        },
                        {
                            title: 'Longitude',
                            value: `${formState.longitude} ${formState.lonDirection}`,
                        },
                    ]}
                />
                <div className="review-row">
                    <ReviewItem title="Coordinate Reference System" value={formState.coordinateReferenceSystem?.name} />
                    <ReviewItem
                        title="Coordinate Reference System Additional Info"
                        value={formState.coordinateAdditionalInfo}
                        fullWidth
                    />
                </div>
                <div className="review-row">
                    <ReviewItem title="Planned Total Depth/TVDSS" value={formState.plannedTVDSS} />
                    <ReviewItem title="Planned Total Depth/MD" value={formState.plannedMD} />
                    <ReviewItem
                        title="AFE Cost"
                        value={`${formState.afeCostType?.text.toString() ?? '?'} ${formState.afeCostValue ?? '--'}`}
                    />
                    <ReviewItem title="AFE Days" value={formState.afeDays ?? '--'} />
                </div>
                <div className="review-row">
                    <ReviewItem title="WCEI Level" value={formState.wcei?.text} />
                </div>
            </ReviewAccordion>

            <ReviewAccordion
                title={`04 ${FormSections.NOTES.title}`}
                isEditable={isEditable}
                onEditClicked={() => onEditFromReview(3)}
            >
                <div className="review-row">
                    <ReviewItem title="Notes" value={formState.notes} />
                </div>
            </ReviewAccordion>

            <ReviewAccordion
                title={`05 ${FormSections.USER_ROLES.title}`}
                isEditable={isEditable}
                onEditClicked={() => onEditFromReview(4)}
            >
                {formState.ownership?.key === 'E' ? (
                    <>
                        <ReviewItemRow
                            items={[
                                {
                                    title: 'Exploration Manager',
                                    value: (
                                        <PersonaFromSigner
                                            signer={getSignerOfRole(formState.signers, WorkflowRole.ExplorationManager)}
                                        />
                                    ),
                                },
                                {
                                    title: 'Exploration Manager Deputy',
                                    value: (
                                        <PersonaFromSigner
                                            signer={getSignerOfRole(
                                                formState.signers,
                                                WorkflowRole.ExplorationManagerDeputy,
                                            )}
                                        />
                                    ),
                                },
                            ]}
                        />
                        <ReviewItemRow
                            items={[
                                {
                                    title: 'Area Manager',
                                    value: (
                                        <PersonaFromSigner
                                            signer={getSignerOfRole(formState.signers, WorkflowRole.AreaManager)}
                                        />
                                    ),
                                },
                                {
                                    title: 'Area Manager Deputy',
                                    value: (
                                        <PersonaFromSigner
                                            signer={getSignerOfRole(formState.signers, WorkflowRole.AreaManagerDeputy)}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </>
                ) : (
                    <ReviewItemRow
                        items={[
                            {
                                title: 'Project Manager',
                                value: (
                                    <PersonaFromSigner
                                        signer={getSignerOfRole(formState.signers, WorkflowRole.ProjectManager)}
                                    />
                                ),
                            },
                            {
                                title: 'Project Manager Deputy',
                                value: (
                                    <PersonaFromSigner
                                        signer={getSignerOfRole(formState.signers, WorkflowRole.ProjectManagerDeputy)}
                                    />
                                ),
                            },
                        ]}
                    />
                )}
            </ReviewAccordion>
        </div>
    );
};

export default Review;
