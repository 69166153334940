import './TargetList.scss';

import { useState } from 'react';
import _ from 'underscore';

import { SelectionMode } from '@fluentui/react';

import React from 'react';
import { useInputOptions } from '../../features/app/api/get-input-options';
import { FormData } from '../../models/FormRoutesStates';
import { ITargetItem } from '../../models/ITargetItem';
import { useFormContext } from '../../features/form/stores/form-context';
import { FormMode } from '../../utils/FormMode';
import GenericList from '../generic-list/GenericList';
import TargetFormModal from '../target-form-modal/TargetFormModal';
import { TargetListColumns } from './TargetListColumns';

export interface TargetListProps {
    estimatedTargets: ITargetItem[];
    setState: React.Dispatch<React.SetStateAction<FormData>>;
    mode: FormMode;
    // setEstimatedTargets: (targets: ITargetItem[]) => void;
}

const TargetList = (props: TargetListProps) => {
    const { formState, setFormState } = useFormContext();

    const [showUpsertDialog, setShowUpsertDialog] = useState<boolean>(false);
    const [dialogData, setDialogData] = useState<ITargetItem>({
        id: '',
        selected: false,
        userEdited: true,
        targetName: null,
        aliasName: null,
        targetAge: null,
        lithology: null,
        tvdss: -1,
        md: -1,
        well_cd: '',
    });

    // const formationAgeMutation = useFormationAge({
    //     mutationConfig: {
    //         onSuccess: (data) => {
    //             setFormState((prev) => ({
    //                 ...prev,
    //                 formationAge: data,
    //             }));
    //         },
    //     },
    // });

    const {
        data: { targetAgeOptions, lithologyOptions },
    } = useInputOptions();

    // const mode = useSelector<GlobalState, FormMode>((state) => state.form.mode);
    const isReviewedEdit = props.mode === FormMode.ReviewedEdit;

    return (
        <>
            <GenericList
                columns={TargetListColumns({
                    onEditTarget: (target) => {
                        setShowUpsertDialog(true);
                        setDialogData(target);
                    },
                    isDisabled: isReviewedEdit,
                })}
                items={props.estimatedTargets}
                initialSelection={(item: ITargetItem) => {
                    let target = _.first(props.estimatedTargets.filter((x) => x.id === item.id));
                    return target.selected;
                }}
                selectionMode={isReviewedEdit ? SelectionMode.none : SelectionMode.multiple}
                onSelectionChanged={(selectedItems: ITargetItem[]) => {
                    props.setState((prev) => ({
                        ...prev,
                        estimatedTargets: prev.estimatedTargets.map((target) => ({
                            ...target,
                            selected: selectedItems.some((x) => x.id === target.id),
                        })),
                    }));
                }}
            />
            {props.estimatedTargets.length === 0 && (
                <div className="target-list-no-data">There are no targets to display yet.</div>
            )}

            <TargetFormModal
                key={dialogData?.id}
                show={showUpsertDialog}
                data={dialogData}
                targetAgeOptions={React.useMemo(
                    () => targetAgeOptions.map((x) => ({ key: x.text, text: x.text })),
                    [targetAgeOptions],
                )}
                lithologyOptions={lithologyOptions}
                onAbort={() => {
                    setDialogData(null);
                    setShowUpsertDialog(false);
                }}
                onAccept={(item: ITargetItem, isUpdate: boolean) => {
                    if (isUpdate) {
                        props.setState((prev) => ({
                            ...prev,
                            estimatedTargets: prev.estimatedTargets.map((target) =>
                                target.id === item.id ? item : target,
                            ),
                        }));
                    } else {
                        props.setState((prev) => ({
                            ...prev,
                            estimatedTargets: [...prev.estimatedTargets, item],
                        }));
                    }
                    setDialogData(null);
                    setShowUpsertDialog(false);
                }}
                onDelete={(target: ITargetItem) => {
                    props.setState((prev) => ({
                        ...prev,
                        estimatedTargets: prev.estimatedTargets.filter((x) => x.id !== target.id),
                    }));
                }}
            />
        </>
    );
};

export default TargetList;
