import { WorkflowSigner } from '../../models/SpudLetterDto';
import { SpudLetterStatus } from '../../models/SpudLetterStatus';

export enum NavbarState {
  Home,
  SelectedFiles,
  SpudLetterDetail,
  SpudLetterForm,
  Admin,
  Dev
}

export interface IBaseFile {
  wellCode: string;
  locked: boolean;
  status: SpudLetterStatus;
  docwareDocumentId?: string;
  documentName?: string;
  ownership: string;
  signers: WorkflowSigner[];
}

export interface ToolbarState {
  navbarState: NavbarState;
  selectedFiles: IBaseFile[];
  currentFile: IBaseFile | null;
  showLockedModal: boolean;
  showDistributeModal: boolean;
}

export const DEFAULT_TOOLBAR_STATE: ToolbarState = {
  navbarState: NavbarState.Home,
  selectedFiles: [],
  currentFile: null,
  showLockedModal: false,
  showDistributeModal: false,
};
