import { USERS_URLS } from "../../utils/api-urls";
import { UserRole } from "../../models/UserRole";
import {
  AjaxService,
  createEmptyRequest,
  IAPIRequest,
  IAPIResponse,
} from "../internal/ajax-service";

const UserController = {
  getSelf: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_USER_URL;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getUsers: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_USERS;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getApprovers: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_APPROVERS;
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getCountryTightUsers: async (
    wellCode: string,
    countryCode: string,
    callback?: (response: any) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GET_COUNTRY_TIGHT_USERS.replace(
      "{wellCode}",
      wellCode
    ).replace("{countryCode}", countryCode);
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getGraphUsers: async (search: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.GRAPH_USERS.replace("{search}", search);
    request.method = "GET";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  updateRole: async (
    userId: string,
    roles: Array<UserRole>,
    callback?: (response: any) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.UPDATE_ROLES.replace("{id}", userId);
    request.method = "POST";
    request.payload = roles;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  syncPermissions: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.SYNC_PERMISSIONS;
    request.method = "POST";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  logout: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = USERS_URLS.LOGOUT;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  getAuthProblemAsync: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = "/user/problem";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default UserController;
