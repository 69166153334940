import { useDispatch, useSelector } from 'react-redux';

import { FileUtils } from '../../../models/FileUtils';
import ApiService from '../../../services/api-service';
import { APISettings } from '../../../services/internal/ajax-service';
import { useLoaderContext } from '../../../stores/loader-context';
import { AppState } from '../../../stores/store';
import { DOCUMENT_URLS } from '../../../utils/api-urls';
import { FormMode } from '../../../utils/FormMode';
import WindowToast from '../../../utils/window-toast';
import { addAttachment, deleteAttachment } from '../stores/attachments-slice';
import { useFormModeContext } from '../stores/form-mode-context';
import { defaultCategoryKey } from '../utils/attachments';
import {
    AttachmentContainer,
    ErrorType,
} from './attachment-container/attachment-container';
import FileUploadBoxPreview from './file-upload-box-preview/file-upload-box-preview';

interface FormSummaryDragAndDropProps {
    wellCode: string;
    mode: FormMode;
}

const FormSummaryDragAndDrop = (props: FormSummaryDragAndDropProps) => {
    const { wellCode } = props;

    const attachments = useSelector(
        (state: AppState) => state.attachments.attachments,
    );
    const loader = useLoaderContext();
    const dispatch = useDispatch();
    const { mode } = useFormModeContext();

    const onAttachmentDelete = (wellCode: string, fileId: string) => {
        if (attachments?.length > 0) {
            dispatch(deleteAttachment({ id: fileId }));
            //         loader.show();
            // ApiService.DocumentController.deleteAttachment(wellCode, fileId)
            //     .then((response) => {
            //         if (response.error !== null) throw response.error;
            //     })
            //     .catch(WindowToast.error)
            //     .finally(loader.hide);
        }
    };

    const onAddAttachments = async (files: File[]) => {
        loader.show();
        let errorType: ErrorType = '';
        await ApiService.DocumentController.uploadAttachments(
            props.wellCode,
            files,
        )
            .then((response) => {
                if (response.error !== null) {
                    if (
                        response.payload.status === 400 &&
                        response.payload.title === 'Invalid File Type'
                    ) {
                        errorType = 'not-supported-format';
                    } else {
                        errorType = 'generic';
                    }
                } else {
                    dispatch(
                        addAttachment({
                            ...response.payload[0],
                            category: defaultCategoryKey,
                        }),
                    );
                }
            })
            .catch(WindowToast.error)
            .finally(loader.hide);

        return errorType;
    };

    if (props.mode === FormMode.Edit) {
        return (
            <div style={{ padding: '1em' }}>
                <div style={{ margin: '2em 0 1em 0' }}>Attachments</div>
                <AttachmentContainer
                    wellCode={wellCode}
                    preloadFiles={attachments}
                    disabled={props.mode !== FormMode.Edit}
                    onAddAttachments={onAddAttachments}
                    onAttachmentDelete={(fileId) => {
                        onAttachmentDelete(wellCode, fileId);
                    }}
                />
            </div>
        );
    }

    return (
        <div style={{ padding: '1em' }}>
            <div style={{ margin: '2em 0 1em 0' }}>Attachments</div>
            {attachments.length > 0 ? (
                attachments.map((att, i) => (
                    <div style={{ margin: '1em' }} key={i}>
                        <FileUploadBoxPreview
                            fileId={att.id}
                            fileName={att.fileName}
                            fileSize={att.size}
                            category={undefined}
                            lastUpdated={att.uploadTime}
                            onClick={() => {
                                FileUtils.downloadFromUrl(
                                    `${
                                        APISettings.baseUrl
                                    }${DOCUMENT_URLS.ATT.replace(
                                        '{wellCode}',
                                        wellCode,
                                    ).replace(
                                        '{attachmentId}',
                                        att.id + att.extension,
                                    )}`,
                                    att.fileName,
                                );
                            }}
                            readOnly={mode !== FormMode.Edit}
                        />
                    </div>
                ))
            ) : (
                <div style={{ fontWeight: 500, margin: '1em' }}>
                    No attachments found
                </div>
            )}
        </div>
    );
};

export default FormSummaryDragAndDrop;
