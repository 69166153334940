import { useQuery } from '@tanstack/react-query';
import { InputOptionsKeys } from '../../../utils/InputOptionsNames';
import { api } from '../../../lib/api-client';
import { QueryConfig } from '../../../lib/react-query';
import { InputOption, Option } from '../../../models/Options';

const selectFunction = (data: InputOption[]) => {
    let ownershipOptions: Option[] = [];
    let onshoreOptions: Option[] = [];
    let referenceSystemOptions: Option[] = [];
    let pathOptions: Option[] = [];
    let currencyOptions: Option[] = [];
    let mainFluidOptions: Option[] = [];
    let trapTypeOptions: Option[] = [];
    let productionTypeOptions: Option[] = [];
    let wceiOptions: Option[] = [];
    let targetAgeOptions: Option[] = [];
    let lithologyOptions: Option[] = [];
    let coordinateTypeOptions: Option[] = [
        { key: "dd", text: "DD" },
        { key: "dms", text: "DMS" },
    ];

    return {
        ownershipOptions: data.find((x) => x.name === InputOptionsKeys.OWNERSHIP)?.options ?? [],
        onshoreOptions: data.find((x) => x.name === InputOptionsKeys.ONSHORE_OFFSHORE)?.options ?? [],
        referenceSystemOptions: data.find((x) => x.name === InputOptionsKeys.REFERENCE_SYSTEM)?.options ?? [],
        pathOptions: data.find((x) => x.name === InputOptionsKeys.PATH)?.options ?? [],
        currencyOptions: data.find((x) => x.name === InputOptionsKeys.CURRENCY)?.options ?? [],
        mainFluidOptions: data.find((x) => x.name === InputOptionsKeys.MAIN_FLUID)?.options ?? [],
        trapTypeOptions: data.find((x) => x.name === InputOptionsKeys.TRAP_TYPE)?.options ?? [],
        productionTypeOptions: data.find((x) => x.name === InputOptionsKeys.PRODUCTION_TYPE)?.options ?? [],
        wceiOptions: data.find((x) => x.name === InputOptionsKeys.WCEI)?.options ?? [],
        targetAgeOptions: data.find((x) => x.name === InputOptionsKeys.TARGET_NAME)?.options ?? [],
        lithologyOptions: data.find((x) => x.name === InputOptionsKeys.LITHOLOGY)?.options ?? [],
        coordinateTypeOptions
    }
}

export const getInputOptions = async (): Promise<InputOption[]> => {
    return api.get("/InputOptions")
}

export const getInputOptionsQueryOptions = () => {
    return {
        queryKey: ['input-options'],
        queryFn: () => getInputOptions(),
        select: selectFunction
    };
};

type UseInputOptionsOptions = {
    queryConfig?: QueryConfig<typeof getInputOptionsQueryOptions>;
};

export const useInputOptions = ({ queryConfig }: UseInputOptionsOptions = {}) => {
    return useQuery({
        ...getInputOptionsQueryOptions(),
        ...queryConfig,
        placeholderData: [],
        staleTime: 1000 * 60 * 10
    });
};
