import {useEffect, useState} from 'react';
import Icons from '../../utils/FabricIconsOutlet';
import { Icon } from "@fluentui/react";
import './DetailBox.scss';

export const DetailBox = (props: any) => {
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [extraData, setExtraData] = useState<any[]>([]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    setExtraData(props.extraData ?? []);
  }, [props.extraData]);

  return (
    <div className="detail-box-card" style={props.title.indexOf('(0)') !== -1 ? {display: 'none'} : {}}>
      <div className="detail-box-card-title">{props.title}</div>
      {data.map((x: any, i: any) => {
        return (
          <div key={i}>
            {x.title && (
              <div className="detail-box-card-primary">
                {x.icon ? (
                  <span className="detail-box-data-icon">
                    <div className="detail-box-data-icon-i"><Icon iconName={x.icon} /></div>
                  </span>
                ) : (
                  <span></span>
                )}{' '}
                <span className="detail-box-data-label">{x.title}</span>
              </div>
            )}
            {x.data && <div className="detail-box-card-secondary">{x.data}</div>}
          </div>
        );
      })}
      {!seeMore && extraData.length > 0 && (
        <div
          className="detail-box-card-link"
          onClick={() => {
            setSeeMore(true);
          }}>
          {'View more'}
        </div>
      )}
      {seeMore &&
        extraData.map((x: any, i: any) => {
          return (
            <div key={i}>
              {x.title && (
                <div className="detail-box-card-primary">
                  {x.icon ? (
                    <span className="detail-box-data-icon">
                      <div className="detail-box-data-icon-i">{Icons.getIcon(x.icon)}</div>
                    </span>
                  ) : (
                    <span></span>
                  )}{' '}
                  <span className="detail-box-data-label">{x.title}</span>
                </div>
              )}
              {x.data && <div className="detail-box-card-secondary">{x.data}</div>}
            </div>
          );
        })}
    </div>
  );
};
