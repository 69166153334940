import { useQuery } from '@tanstack/react-query';
import { IActivity } from '../../../components/activity-column/activity-column';
import { api } from '../../../lib/api-client';
import { QueryConfig } from '../../../lib/react-query';

export const getDocumentActivities = async (wellCode): Promise<IActivity[]> => {
    return api.get(`/Activities/reference?wellCode=${wellCode}`)
}

export const getDocumentActivitiesQueryOptions = (wellCode: string) => {
    return {
        queryKey: ['document-activities', { wellCode }],
        queryFn: () => getDocumentActivities(wellCode),
    };
};

type UseDocumentActivitiesOptions = {
    wellCode?: string
    queryConfig?: QueryConfig<typeof getDocumentActivitiesQueryOptions>;
};

export const useDocumentActivities = ({ wellCode, queryConfig }: UseDocumentActivitiesOptions) => {
    return useQuery({
        ...getDocumentActivitiesQueryOptions(wellCode),
        ...queryConfig,
        placeholderData: []
    });
};
