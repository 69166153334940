import { LatLngExpression } from "leaflet";

export const useMapCenter = (latitude: string, longitude: string, latDirection: string, lonDirection: string) => {
    let center: LatLngExpression = {
        lat: isNaN(+latitude)
            ? 0
            : latDirection === "N"
                ? +latitude
                : -latitude,
        lng: isNaN(+longitude)
            ? 0
            : lonDirection === "E"
                ? +longitude
                : -longitude,
    };
    return center
}