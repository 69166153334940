import { LATEST_EXTERNAL_DATA_URLS, SPUD_LETTER_URLS } from '../../utils/api-urls';
import { BaseQueryObject } from '../../models/ApiTypes';
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from '../internal/ajax-service';

const LatestExternalDataController = {
  getChanges: async (wellCode: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = LATEST_EXTERNAL_DATA_URLS.GET_CHANGES.replace('{wellCode}', wellCode);
    request.method = 'GET';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default LatestExternalDataController;
