import { reactPlugin } from "@/app-insights";
import ErrorPage from "@/components/error-page/error-page";
import Loader from "@/components/loader/loader-component";
import { queryClient } from "@/lib/react-query";
import { LoaderContextProvider } from "@/stores/loader-context";
import store from "@/stores/store";
import { UserContextProvider } from "@/stores/user-context";
import { PartialTheme, ThemeProvider } from "@fluentui/react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const appTheme: PartialTheme = {
    palette: {
        themePrimary: "#3b84aa",
        themeLighterAlt: "#f5f9fc",
        themeLighter: "#d8e9f2",
        themeLight: "#b9d6e6",
        themeTertiary: "#7db1cd",
        themeSecondary: "#4d91b5",
        themeDarkAlt: "#36779a",
        themeDark: "#2d6482",
        themeDarker: "#214a60",
        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#a19f9d",
        neutralSecondary: "#605e5c",
        neutralPrimaryAlt: "#3b3a39",
        neutralPrimary: "#323130",
        neutralDark: "#201f1e",
        black: "#000000",
        white: "#ffffff",
    },
};

type AppProviderProps = {
    children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
    return (
        <React.StrictMode>
            <React.Suspense fallback={<Loader display={true} />}>
                <ErrorBoundary fallback={<ErrorPage title={""} message={""} page={"Application"} />}>
                    <ThemeProvider theme={appTheme}>
                        <AppInsightsContext.Provider value={reactPlugin}>
                            <Provider store={store}>
                                <LoaderContextProvider>
                                    <UserContextProvider>
                                        <QueryClientProvider client={queryClient}>
                                            <ReactQueryDevtools initialIsOpen={false} />
                                            {children}
                                        </QueryClientProvider>
                                    </UserContextProvider>
                                </LoaderContextProvider>
                            </Provider>
                        </AppInsightsContext.Provider>
                    </ThemeProvider>
                </ErrorBoundary>
            </React.Suspense>
        </React.StrictMode>
    );
};
