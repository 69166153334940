import './attachment-container.scss';

import { useState } from 'react';

import { Banner, LabelButton } from '@Eni/docware-fe-master';

import { FormMode } from '@/utils/FormMode';
import { FileInfo, FileUtils } from '../../../../models/FileUtils';
import { APISettings } from '../../../../services/internal/ajax-service';
import { DOCUMENT_URLS } from '../../../../utils/api-urls';
import Icons from '../../../../utils/FabricIconsOutlet';
import { useFormModeContext } from '../../stores/form-mode-context';
import { DragAndDropContainer } from '../drag-and-drop-container/drag-and-drop-container';
import FileUploadBoxPreview from '../file-upload-box-preview/file-upload-box-preview';

interface AttachmentContainerProps {
    wellCode: string;
    preloadFiles: FileInfo[];
    onAttachmentDelete: (attachmentId: string) => void;
    onAddAttachments: (files: File[]) => Promise<ErrorType>;
    disabled: boolean;
}

export type ErrorType = 'generic' | 'not-supported-format' | '';

var FILES: File[] = [];

export const AttachmentContainer = (props: AttachmentContainerProps) => {
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [errorType, setErrorType] = useState<ErrorType>('');

    const { mode } = useFormModeContext();

    const getContainerContent = () => {
        if (errorType === 'generic') {
            return {
                icon: (
                    <div style={{ color: '#a4262c' }}>
                        {Icons.getIcon('SyncToPC', '2em')}
                    </div>
                ),
                message: (
                    <div style={{ color: '#a4262c' }}>
                        Ops! <strong>There was an issue uploading.</strong>
                    </div>
                ),
                buttonLabel: 'Drag or upload file again',
            };
        }
        if (errorType === 'not-supported-format') {
            return {
                icon: (
                    <div style={{ color: '#a4262c' }}>
                        {Icons.getIcon('StatusErrorFull', '2em')}
                    </div>
                ),
                message: (
                    <div style={{ color: '#a4262c' }}>
                        Ouch! <strong>Format not supported!</strong>
                    </div>
                ),
                buttonLabel: 'Upload another file',
            };
        }

        return {
            icon: Icons.getIcon('CloudUpload', '2em'),
            message: <div>Upload any attachment file.</div>,
            buttonLabel: 'Choose attachments',
        };
    };

    const proceedAndUpload = async () => {
        setErrorMsg('');
        if (FILES != null) {
            if (FILES.length > 0) {
                props
                    .onAddAttachments(FILES)
                    .then((value) => setErrorType(value));
            }
        }
    };

    const onDropFiles = (files: File[]) => {
        FILES = files;
        proceedAndUpload();
    };

    const content = getContainerContent();

    return (
        <div style={{ cursor: 'pointer' }}>
            <DragAndDropContainer
                multiple={true}
                disableInternalListing={true}
                preLoadFiles={props.preloadFiles}
                onDrop={onDropFiles}
                lowerElement={
                    <div className="fake-drag-drop-button">
                        <LabelButton
                            text={content.buttonLabel}
                            orangeSolid
                            icon={'OpenFile'}
                            onClick={() => {}}
                        />
                    </div>
                }
                enabled={!props.disabled}
                icon={content.icon}
                title={content.message}
                allowFileListing={true}
                topOfListView={
                    <Banner
                        type={'error'}
                        message={errorMsg}
                        enabled={errorMsg.length > 0}
                    />
                }
                customListView={(items: FileInfo[]) => {
                    return items.map((attachment, index) => {
                        return (
                            <div style={{ margin: '1em' }} key={attachment.id}>
                                <FileUploadBoxPreview
                                    fileId={attachment.id}
                                    fileName={attachment.fileName}
                                    fileSize={attachment.size}
                                    category={attachment.category}
                                    lastUpdated={attachment.uploadTime}
                                    onExitClick={
                                        props.disabled
                                            ? () => {}
                                            : props.onAttachmentDelete
                                    }
                                    onClick={() => {
                                        FileUtils.downloadFromUrl(
                                            `${
                                                APISettings.baseUrl
                                            }${DOCUMENT_URLS.ATT.replace(
                                                '{wellCode}',
                                                props.wellCode,
                                            ).replace(
                                                '{attachmentId}',
                                                attachment.id +
                                                    attachment.extension,
                                            )}`,
                                            attachment.fileName,
                                        );
                                    }}
                                    readOnly={mode !== FormMode.Edit}
                                />
                            </div>
                        );
                    });
                }}
            />
        </div>
    );
};
