import { useParams } from 'react-router-dom';

import { useDeAuthorize } from '../api/de-authorize';

import { DetailState } from '@/app/routes/detail';
import ModalDialog from '@/components/modal-dialog/modal-dialog';
import { SpudLetterStatus } from '@/models/SpudLetterStatus';
import { CommonModalButtons } from '@/utils/ModalUtils';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { useState } from 'react';

type DeAuthorizationModalProps = {
  closeModal: () => void;
  enableModal: boolean;
  detailState: DetailState;
};

export const DeAuthorizationModal = ({
  closeModal,
  enableModal,
  detailState,
}: DeAuthorizationModalProps) => {
  const params = useParams();
  const wellCode = params.wellCode as string;

  const [newStatus, setNewStatus] = useState<SpudLetterStatus>(
    detailState.status === SpudLetterStatus.Signed &&
      detailState.ownership === 'E'
      ? SpudLetterStatus.ReviewedDraft
      : SpudLetterStatus.Draft,
  );

  const options = [
    { key: SpudLetterStatus.ReviewedDraft, text: 'Reviewed Draft' },
    { key: SpudLetterStatus.Draft, text: 'Draft' },
  ] as IDropdownOption[];

  const message = `Are you sure you want to de-authorize the Spud Letter? The Spud Letter will have status ${SpudLetterStatus[newStatus]}`;

  const deAuthorizeMutation = useDeAuthorize({
    wellCode,
    targetStatus: newStatus,
    mutationConfig: {
      onSuccess: closeModal,
    },
  });

  const buttons = CommonModalButtons.confirmCancelButtons(
    () => deAuthorizeMutation.mutate({ wellCode, targetStatus: newStatus }),
    closeModal,
  );

  return (
    <ModalDialog
      modalTitle="De-Authorize Spud Letter"
      modalInnerComponent={
        detailState.ownership === 'E' &&
        detailState.status === SpudLetterStatus.Signed ? (
          <Dropdown
            options={options}
            onChange={(event, option) =>
              setNewStatus(option.key as SpudLetterStatus)
            }
            selectedKey={newStatus}
          />
        ) : null
      }
      modalMessage={message}
      enableModal={enableModal}
      modalButtons={buttons}
      onAbort={closeModal}
    />
  );
};
