import { INPUT_INFO_URLS } from "../../utils/api-urls";
import { InputInfo } from "../../models/Options";
import {
  createEmptyRequest,
  AjaxService,
  IAPIRequest,
  IAPIResponse,
} from "../internal/ajax-service";

const InputInfoController = {
  getInputInfos: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = INPUT_INFO_URLS.BASE_URL;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  setInputInfo: async (body: InputInfo, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = INPUT_INFO_URLS.SET_INPUT_INFO;
    request.method = "POST";
    request.payload = body;
    request.successMessage = "Record updated.";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  deleteInputInfo: async (
    body: InputInfo,
    callback?: (response: any) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = INPUT_INFO_URLS.BASE_URL;
    request.method = "DELETE";
    request.payload = body;
    request.successMessage = "Record deleted.";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
 
};

export default InputInfoController;
