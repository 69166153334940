import {
  Dropdown,
  IDropdownProps,
  TextField,
  ITextFieldProps,
  DatePicker,
  IDatePickerProps,
  TagPicker,
  ITagPickerProps,
  IPeoplePickerProps,
  SearchBox,
  ISearchBoxProps,
  IDropdownOption,
  IComboBoxProps,
  ComboBox,
  IChoiceGroupProps,
  ChoiceGroup,
  IToggleProps,
  Toggle,
  ITimePickerProps,
  TimePicker,
  ISpinButtonProps,
  SpinButton,
} from '@fluentui/react';
import React, { createRef, useEffect, useState } from 'react';
import { PeoplePicker, PeoplePickerProps } from '../people-picker/PeoplePicker';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { IconButton } from '@fluentui/react/lib/Button';
import { useId } from '@fluentui/react-hooks';
import './FluentUIDecorator.scss';

export interface IFluentUIDecorator {
  fluentComponent: any;
  denyComponentHardReset?: boolean;
  required?: boolean;
  errorMessage?: string | null;
  directComponentInjection?: JSX.Element;
  label: string | null;
  info: string | null;
  noLabels?: boolean;
  className?: string;
  validation?: (content: any) => boolean;
}

export const _SearchBox = (props: ISearchBoxProps) => {
  let advancedProps: any = {};
  advancedProps.props = props;
  advancedProps.component = 'SearchBox';
  return advancedProps;
};

export const _PeoplePicker = (props: PeoplePickerProps) => {
  let advancedProps: any = {};
  advancedProps.props = props;
  advancedProps.component = 'PeoplePicker';
  return advancedProps;
};

export const _TagPicker = (props: ITagPickerProps) => {
  let advancedProps: any = {};
  advancedProps.props = props;
  advancedProps.component = 'TagPicker';
  return advancedProps;
};

export const _DatePicker = (props: IDatePickerProps) => {
  let advancedProps: any = {};
  advancedProps.props = props;
  advancedProps.component = 'DatePicker';
  return advancedProps;
};

export const _TimePicker = (props: ITimePickerProps) => {
  let advancedProps: any = {};
  advancedProps.props = props;
  advancedProps.component = 'TimePicker';
  return advancedProps;
};

export const _Dropdown = (props: IDropdownProps) => {
  let advancedProps: any = {};
  advancedProps.props = props;
  advancedProps.component = 'Dropdown';
  return advancedProps;
};

export const _TextField = (props: ITextFieldProps) => {
  let advancedProps: any = {};
  advancedProps.props = props;
  advancedProps.component = 'TextField';
  return advancedProps;
};

export const _ComboBox = (props: IComboBoxProps) => {
  let advancedProps: any = {};
  advancedProps.props = props;
  advancedProps.component = 'ComboBox';
  return advancedProps;
};

export const _ChoiceGroup = (props: IChoiceGroupProps) => {
  let advancedProps: any = {};
  advancedProps.props = props;
  advancedProps.component = 'ChoiceGroup';
  return advancedProps;
};

export const _Toggle = (props: IToggleProps) => {
  let advancedProps: any = {
    direction: 'horizontal',
  };
  advancedProps.props = props;
  advancedProps.component = 'Toggle';
  return advancedProps;
};

export const _SpinButton = (props: ISpinButtonProps) => {
  let advancedProps: any = {};
  advancedProps.props = props;
  advancedProps.component = 'SpinButton';
  return advancedProps;
};

export interface IFluentUIDecoratorTypes {
  SearchBox: (props: ISearchBoxProps) => any;
  PeoplePicker: (props: PeoplePickerProps) => any;
  TagPicker: (props: ITagPickerProps) => any;
  DatePicker: (props: IDatePickerProps) => any;
  TimePicker: (props: ITimePickerProps) => any;
  Dropdown: (props: IDropdownProps) => any;
  TextField: (props: ITextFieldProps) => any;
  ComboBox: (props: IComboBoxProps) => any;
  ChoiceGroup: (props: IChoiceGroupProps) => any;
  Toggle: (props: IToggleProps) => any;
  SpinButton: (props: ISpinButtonProps) => any;
}

export const FluentUIDecoratorTypes: IFluentUIDecoratorTypes = {
  SearchBox: _SearchBox,
  PeoplePicker: _PeoplePicker,
  TagPicker: _TagPicker,
  DatePicker: _DatePicker,
  TimePicker: _TimePicker,
  Dropdown: _Dropdown,
  TextField: _TextField,
  ComboBox: _ComboBox,
  ChoiceGroup: _ChoiceGroup,
  Toggle: _Toggle,
  SpinButton: _SpinButton,
};

const getInputSuggestedValue = (fluentUI: any) => {
  let props = fluentUI.props;
  let value = undefined;
  if (props) {
    if (value === undefined) {
      value = props.value;
    }
    if (value === undefined) {
      value = props.selectedItems;
    }
    if (value === undefined) {
      value = props.selectedKey;
    }
    if (value === undefined) {
      value = props.selectedUsers;
    }
  }

  return value;
};

const calloutProps = { gapSpace: 0 };

const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' },
};

export const FluentUIDecorator = (props: IFluentUIDecorator) => {
  const [state, setState] = useState<string>('pending');
  const [value, setValue] = useState<any>(null);
  const [focus, setFocus] = useState<boolean>(false);
  const [forceReload, setForceReload] = useState<boolean>(false);
  const tooltipId = useId('tooltip');

  const getInputState = (value: any, fluentUI: any, validation: (content: any) => boolean) => {
    let validity = props.required ? 'invalid' : 'pending';

    if (value !== undefined && value != null) {
      if (!validation(value)) {
        return 'invalid';
      }

      if (fluentUI.component === 'TextField') {
        if (value === '') {
          return validity;
        } else {
          return 'valid';
        }
      }
      if (fluentUI.component === 'SpinButton') {
        if (value === '') {
          return validity;
        } else {
          return 'valid';
        }
      }
      if (fluentUI.component === 'SearchBox') {
        if (value === '') {
          return validity;
        } else {
          return 'valid';
        }
      }
      if (fluentUI.component === 'Dropdown') {
        return 'valid';
      }
      if (fluentUI.component === 'DatePicker') {
        return 'valid';
      }
      if (fluentUI.component === 'TimePicker') {
        return 'valid';
      }
      if (fluentUI.component === 'DocumentPicker') {
        return value.length > 0 ? 'valid' : validity;
      }
      if (fluentUI.component === 'TagPicker') {
        return value.length > 0 ? 'valid' : validity;
      }
      if (fluentUI.component === 'PeoplePicker') {
        return value.length > 0 ? 'valid' : validity;
      }
      if (fluentUI.component === 'ChoiceGroup') {
        if (value === '') {
          return validity;
        } else {
          return 'valid';
        }
      }
      if (fluentUI.component === 'Toggle') {
        return 'valid';
      }
    }

    return 'pending';
  };

  const overrideChangeMethods = (fluentUI: any) => {
    let onChangeFromProps = fluentUI.props.onChange;
    fluentUI.props.onChange = (event: any, value: any) => {
      if (value === undefined) {
        setValue(event);
        if (onChangeFromProps) {
          onChangeFromProps(event);
        }
      } else {
        setValue(value);
        if (onChangeFromProps) {
          onChangeFromProps(event, value);
        }
      }
    };

    let onPeopleChangedFromProps = fluentUI.props.onPeopleChanged;
    fluentUI.props.onPeopleChanged = (value: any) => {
      setValue(value);
      if (onPeopleChangedFromProps) {
        onPeopleChangedFromProps(value);
      }
    };

    let onSelectDateFromProps = fluentUI.props.onSelectDate;
    fluentUI.props.onSelectDate = (value: any) => {
      setValue(value);
      if (onSelectDateFromProps) {
        onSelectDateFromProps(value);
      }
    };

    return fluentUI;
  };

  const buildComponent = (fluentUI: any) => {
    fluentUI = overrideChangeMethods(fluentUI);

    if (fluentUI.component === 'Dropdown') {
      let dropdownProps: any = { ...fluentUI.props };

      if (dropdownProps.options) {
        dropdownProps.options = dropdownProps.options.sort((x: any, y: any) => {
          let targetA: string = x.order ?? x.text;
          let targetB: string = y.order ?? y.text;

          if (targetA > targetB) {
            return 1;
          }
          if (targetB > targetA) {
            return -1;
          }
          return 0;
        });
      }

      return (
        <Dropdown
          {...dropdownProps}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      );
    }
    if (fluentUI.component === 'TextField') {
      return (
        <TextField
          {...fluentUI.props}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      );
    }
    if (fluentUI.component === 'SpinButton') {
      return (
        <SpinButton
          {...fluentUI.props}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      );
    }
    if (fluentUI.component === 'DatePicker') {
      return (
        <DatePicker
          style={{ height: '32px' }}
          {...fluentUI.props}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      );
    }
    if (fluentUI.component === 'TimePicker') {
      return (
        <TimePicker
          style={{ height: '32px' }}
          {...fluentUI.props}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      );
    }
    if (fluentUI.component === 'TagPicker') {
      return (
        <TagPicker
          {...fluentUI.props}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      );
    }
    if (fluentUI.component === 'PeoplePicker') {
      return (
        <PeoplePicker
          {...fluentUI.props}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      );
    }
    if (fluentUI.component === 'SearchBox') {
      return (
        <SearchBox
          {...fluentUI.props}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      );
    }
    if (fluentUI.component === 'ComboBox') {
      return (
        <ComboBox
          {...fluentUI.props}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      );
    }
    if (fluentUI.component === 'ChoiceGroup') {
      return (
        <ChoiceGroup
          {...fluentUI.props}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      );
    }
    if (fluentUI.component === 'Toggle') {
      return (
        <Toggle
          {...fluentUI.props}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      );
    }

    return <div>Unknown input</div>;
  };

  //* init */
  useEffect(() => {
    if (props.fluentComponent) {
      let suggestedValue: any = getInputSuggestedValue(props.fluentComponent);
      setValue(suggestedValue);

      if (props.denyComponentHardReset !== true) {
        if (!suggestedValue) {
          setForceReload(true);
          setTimeout(() => {
            setForceReload(false);
          }, 0);
        }
      }
    }
  }, [props.fluentComponent]);

  const computeState = () => {
    let validation =
      props.validation ??
      ((content: any) => {
        return true;
      });
    return getInputState(value, props.fluentComponent, validation);
  };

  useEffect(() => {
    if (props.fluentComponent) {
      let state = computeState();
      setState(state);
    }
  }, [value]);

  const getHeightStyle = (fluentUI: any) => {
    if (fluentUI) {
      if (fluentUI.component === 'TextField' && fluentUI.props.multiline) {
        return {};
      }
    }

    return { minHeight: '34px' };
  };

  let component = props.fluentComponent ? (
    buildComponent(props.fluentComponent)
  ) : props.directComponentInjection ? (
    props.directComponentInjection
  ) : (
    <div></div>
  );

  let inputInfo = props.info ? props.info : 'No description has been provided for this input.';

  const getExtraClass = () => {
    if (props.fluentComponent && props.fluentComponent.component) {
      switch (props.fluentComponent.component) {
        case 'TagPicker':
          return 'fluent-ui-decorator-input-direct-wrap-whiter';
        case 'PeoplePicker':
          return 'fluent-ui-decorator-input-direct-wrap-whiter';
        default:
          return '';
      }
    }
    return '';
  };

  return (
    <div>
      <div className={'fluent-ui-decorator-main-wrap ' + props.className}>
        {(props.label != null || props.info != null) && props.noLabels !== true && (
          <div className='fluent-ui-decorator-label'>
            {props.label} {props.required && <span className='fluent-ui-decorator-required-ast'>*</span>}
            <span className='fluent-ui-decorator-icon'>
              <TooltipHost
                content={inputInfo}
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={tooltipId}
                calloutProps={calloutProps}
                styles={hostStyles}
                setAriaDescribedBy={false}
              >
                <IconButton iconProps={{ iconName: 'Info' }} aria-label='Information about this field.' />
              </TooltipHost>
            </span>
          </div>
        )}
        <div
          className={
            'fluent-ui-decorator-input-direct-wrap ' +
            getExtraClass() +
            ' ' +
            (focus ? 'fluent-ui-decorator-focused' : 'fluent-ui-decorator-' + state)
          }
          style={getHeightStyle(props.fluentComponent)}
        >
          {!forceReload && component}
          {forceReload && component}
        </div>
      </div>
      {state !== 'valid' && props.errorMessage && (
        <div className='fluent-ui-input-error-message'>{props.errorMessage}</div>
      )}
    </div>
  );
};
