import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

export function Root() {
    return (
        <Suspense fallback={'loading'}>
            <ErrorBoundary fallback={<div>Error</div>}>
                <div style={{ overflow: 'hidden' }}>
                    <Outlet />
                </div>
            </ErrorBoundary>
        </Suspense>
    );
}
